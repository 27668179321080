import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BiSolidCheckSquare } from "react-icons/bi";
import { IoIosImages } from "react-icons/io";
import { BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { facilities } from '../data/data';
import { setLogin, setPropertyList } from '../redux/state';
import { toast } from 'react-toastify';

const GCL2 = ({ formData, setFormData, setStep, id, step2data, subID }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("Step2data → :", step2data);
    if (step2data) {
      setFormData((prevData) => ({
        ...prevData,
        facilities: step2data?.facilities,
        listingPhotoPaths: step2data?.listingPhotoPaths,
        title: step2data?.title,
        description: step2data?.description,
        highlight: step2data?.highlight,
        highlightDesc: step2data?.highlightDesc,
        price: step2data?.price,
      }));
    }
    //console.log("step2data bedCount:", step2data.bedCount); // Log step2data.bedCount
    // console.log("(GCL2  useEffect1) formData: ", formData);
  }, []);
  const [facilityDivs, setFacilityDivs] = useState(step2data?.facilities || []);
  const [imgs, setImgs] = useState([]);
  const [isImgsLoaded, setIsImgsLoaded] = useState(false);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const propertyLists = user?.propertyList;
  // console.log("propertyLists: ",propertyLists, "user", user);
  const [placeDesc, setPlaceDesc] = useState({
    title: step2data?.title || "",
    description: step2data?.description || "",
    highlight: step2data?.highlight || "",
    h_description: step2data?.highlightDesc || "",
    price: step2data?.price || "",
  });
  let count = placeDesc.description.trim().split(/\s+/).filter(word => word.length > 0).length;
  //console.log(count)
  const [wordCount, setWordCount] = useState(count)
  //console.log("id", id);  


  // Handle Facility Selection
  const handleFacilityClick = (name) => {
    if (facilityDivs.includes(name)) {
      setFacilityDivs(facilityDivs.filter(selectedName => selectedName !== name));
    } else {
      setFacilityDivs([...facilityDivs, name]);
    }
  };

  //-----------------------------------------------------------
  const convertUrlToFile = async (url) => {
    // Replace backslashes with forward slashes for proper URL formatting
    const formattedUrl = url.replace(/\\/g, '/');
    const finalURL = `${process.env.REACT_APP_BACKEND_URL}/${formattedUrl.replace("public", "")}`;
    //console.log("gtm convert finalURL →", finalURL);
    // console.log(" convert formattedUrl →", formattedUrl);
    const response = await fetch(finalURL);
    //console.log("gtm convert response →", response);
    const blob = await response.blob();
    // console.log("gtm convert blob →", blob)
    const fileName = formattedUrl.split('/').pop()
    const mimeType = blob.type.startsWith('image/') ? blob.type : 'image/jpeg'

    // Create and return a new File object with the correct MIME type
    return new File([blob], fileName, { type: mimeType });
  };


  // Load images from step2data as file objects
  useEffect(() => {
    if (step2data?.listingPhotoPaths && !isImgsLoaded) {
      const loadImages = async () => {
        const convertedImages = await Promise.all(
          step2data.listingPhotoPaths.map((path) => {
            console.log("Path : ", path, "Converted path : ", convertUrlToFile(path));
            return convertUrlToFile(path);
          })
        );
        // Check for duplicates by comparing filenames in imgs and convertedImages
        setImgs((prevImgs) => {
          const prevFileNames = prevImgs.map((img) => img.name); // Collect current filenames
          const filteredImages = convertedImages.filter(
            (img) => !prevFileNames.includes(img.name) // Filter out duplicates
          );
          return [...prevImgs, ...filteredImages]; // Only add new images
        });
        setIsImgsLoaded(true);
      };
      // console.log("(GCL2) convertedImages →", convertedImages);

      loadImages();
    }
  }, [step2data]);

  //-----------------------------------------------------------
  // Handle Image Upload
  const handleUploadImgs = (e) => {
    const newImgs = e.target.files;
    setImgs((prevImgs) => [...prevImgs, ...newImgs]);
  };

  // Handle Image Drag and Drop
  const handleDragImg = (result) => {
    if (!result.destination) return;
    const items = Array.from(imgs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setImgs(items);
  };

  // Handle Image Removal
  const handleRemoveImg = (indexToRemove) => {
    setImgs((prevImgs) =>
      prevImgs.filter((_, index) => index !== indexToRemove)
    );
  };

  // Handle Place Description Change
  const handlePlaceDescChange = (e) => {
    const { name, value } = e.target;
    setPlaceDesc({
        ...placeDesc,
        [name]: value
    });

    if (name === "description") {
        const words = value.trim().split(/\s+/).filter(word => word.length > 0); 
        setWordCount(words?.length); 
    }
};

  const handlePost = async (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      facilityDivs,
      imgs,
      placeDesc
    });
    // console.log("(GCL2) formData → ", formData);
    try {
      const formDataToSubmit = new FormData();
      // Append all the formData fields
      formDataToSubmit.append("category", formData?.category);
      formDataToSubmit.append("type", formData?.type);
      formDataToSubmit.append("streetAddress", formData?.streetAddress);
      formDataToSubmit.append("aptSuite", formData?.aptSuite);
      formDataToSubmit.append("city", formData?.city);
      formDataToSubmit.append("province", formData?.province);
      formDataToSubmit.append("country", formData?.country);
      formDataToSubmit.append("guestCount", formData?.guestCount);
      formDataToSubmit.append("bedroomCount", formData?.bedroomCount);
      formDataToSubmit.append("bedCount", formData?.bedCount);
      formDataToSubmit.append("bathroomCount", formData?.bathroomCount);

      // Append Step 2 fields
      formDataToSubmit.append("facilities", facilityDivs);
      formDataToSubmit.append("title", placeDesc?.title);
      formDataToSubmit.append("description", placeDesc?.description);
      formDataToSubmit.append("highlight", placeDesc?.highlight);
      formDataToSubmit.append("highlightDesc", placeDesc?.h_description);
      formDataToSubmit.append("price", placeDesc?.price);

      // Append images to the formData
      imgs.forEach((photo) => {
        formDataToSubmit.append("listingPhotoPaths", photo);
      });

      /* Send a POST request to server */
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings/${id}/update-photos`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSubmit,
        credentials: 'include'
      });

      if (response.ok) {
        (step2data && step2data?.listID !== undefined)
          ? toast.success("Listing updated successfully!")
          : toast.success("Listing created successfully!")

        const newProperty = await response.json();
        //console.log("(GCL2 response.ok) formDataToSubmit →",formDataToSubmit)

        dispatch(setPropertyList([...propertyLists, newProperty]))

        const updatedHostResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/update-step`, {
          method: "PATCH",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            listStep: 1,
            listId: null
          })
        })
        if (updatedHostResponse.ok) {
          const updatedHost = await updatedHostResponse.json();
          // console.log("updateHost: ", updatedHost);
          dispatch(
            setLogin({
              user: {...user,...updatedHost.data},
              token,
              modelType: "host",
            })
          )


          navigate(`/properties/${id}`);

          //CREATE-LISTING PAGE FINAL SUBMISSION ONLY AFTER GPAY.jsx PAGE - GTM
          //THEN POST REQUEST TO BACKEND
        }
      }
    } catch (err) {
      console.log("Publish Listing failed", err.message);
    }
  };

  return (
    <>
      <div className="py-4">
        <form onSubmit={handlePost}>
          {/* =================================== STEP 2 =================================== */}
          <div>
            <p className='text-slate-700 font-bold text-md sm:text-md md:text-lg lg:text-lg tracking-wider border-b border-slate-700 py-2 mt-8'>
              STEP 2 | MAKE YOUR PLACE STAND OUT
            </p>
            <div className="rounded-md bg-white py-4 px-2 sm:px-2 md:px-4 lg:px-6 mt-2 flex flex-col gap-4">

              <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>
                Tell guests what your place has to offer. <span className="imp">*</span>
              </p>
              <div className='flex flex-wrap gap-3 justify-center px-2 sm:px-6 md:px-10 lg:px-12'>
                {facilities?.map((facility, index) => (
                  <div key={index} className={`relative flex items-center justify-center h-[68px] w-[90px] md:h-20 md:w-28 p-2 rounded-lg cursor-pointer border hover:shadow ${facilityDivs.includes(facility.name) ? 'border border-slate-700 bg-gray-100/[0.5]' : ''}`} onClick={() => handleFacilityClick(facility.name)}>
                    <label htmlFor={facility.name} className={`flex flex-col items-center justify-center gap-2 cursor-pointer}`}>
                      <div className={`text-md sm:textlg lg:text-xl text-gray-700 cursor-pointer`}>
                        {facility.icon}
                      </div>
                      <p className={`text-[10px] sm:text-xs font-bold text-center text-gray-700 cursor-pointer`}>{facility.name}</p>
                    </label>
                    {facilityDivs.includes(facility.name) && (
                      <div className='absolute top-1 right-1'>
                        <BiSolidCheckSquare className='text-slate-700 h-5 w-5' />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>
                Add some images of your place <span className="imp">*</span> <span className="text-xs text-slate-700">(Max. 10 images)</span>
              </p>
              <div className='py-4 px-1 sm:px-6 md:px-10 flex items-center justify-center'>
                <DragDropContext onDragEnd={handleDragImg}>
                  <Droppable droppableId="photos" direction="horizontal">
                    {(provided) => (
                      <div
                        id="photos"
                        className="photos w-full h-full p-0 sm:p-1 lg:p-2 flex justify-around items-center"
                        {...provided?.droppableProps}
                        ref={provided.innerRef}
                      >
                        {imgs.length < 1 && (
                          <>
                            <input id="image" type="file" style={{ display: "none" }} accept="image/*" onChange={handleUploadImgs} multiple />
                            <label htmlFor="image" className="shadow-md p-2 text-gray-700 rounded flex flex-col justify-center items-center cursor-pointer hover:scale-105">
                              <div className="icon cursor-pointer">
                                <IoIosImages />
                              </div>
                              <p className='font-semibold cursor-pointer text-xs'>Upload from your device</p>
                            </label>
                          </>
                        )}

                        {/* Combine uploaded images and server images */}
                        {(imgs?.length >= 1) && (
                          <>
                            {imgs?.map((img, index) => {
                              const objectUrl = URL.createObjectURL(img);
                              // console.log(index, " Object img:", img); // Log the URL for each image
                              // console.log(index, " Object URL:", objectUrl); // Log the URL for each image

                              return (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                  {(provided) => (
                                    <div
                                      className="photo w-[135px] h-[80px] md:w-[250px] md:h-[170px] shadow-md"
                                      ref={provided?.innerRef}
                                      {...provided?.draggableProps}
                                      {...provided?.dragHandleProps}
                                    >
                                      <img src={objectUrl} alt="Uploaded" />
                                      <button
                                        type="button"
                                        className='del_btn absolute top-0 right-0 p-1 bg-white hover:bg-slate-700 text-md cursor-pointer'
                                        onClick={() => handleRemoveImg(index)}
                                      >
                                        <BiTrash className='h-[13px] w-[13px] md:h-[16px] md:w-[16px] text-slate-700 hover:text-white font-bold' />
                                      </button>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}

                            {/* {console.log("Imgs:", imgs)} */}

                            {/* Render images from the server */}
                            {/* {step2data?.listingPhotoPaths && step2data?.listingPhotoPaths?.map((path, index) => {
                          const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/${path?.replace("public", "")}`;
                          //console.log("imageUrl →", imageUrl);
                          return (
                            <div key={index} className="photo w-[135px] h-[80px] md:w-[250px] md:h-[170px] shadow-md">
                              <img
                                src={imageUrl}
                                alt={`Server Image ${index}`}
                                onError={(e) => {
                                  e.target.onerror = null; // Prevents infinite loop on error
                                  // Fallback image
                                }}
                              />
                            </div>
                          );
                        })} */}


                            <input id="image" type="file" style={{ display: "none" }} accept="image/*" onChange={handleUploadImgs} multiple />
                            <label htmlFor="image" className="border border-slate-700 p-2 text-gray-700 rounded flex flex-col justify-center items-center cursor-pointer w-[135px] h-[80px] md:w-[250px] md:h-[170px]">
                              <div className="icon cursor-pointer">
                                <IoIosImages />
                              </div>
                              <p className='cursor-pointer font-semibold text-xs'>Add more images</p>
                            </label>
                          </>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>


              <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>
                Add a title and description to your listing <span className="imp">*</span>
              </p>
              <div className='flex flex-col'>
                <div className="flex flex-col gap-3 p-2 sm:p-6">
                  <input type="text" className='rounded-sm outline-none px-2 py-1 border' placeholder='Title' name='title' value={placeDesc.title} onChange={handlePlaceDescChange} />
                  <textarea
                    className='rounded-sm outline-none px-2 py-1 border break-words resize-none'
                    placeholder='Description (Minimum 200 words)'
                    name='description'
                    value={placeDesc.description}
                    onChange={handlePlaceDescChange}
                    rows={5} /* Adjust rows to your desired height */
                    minLength={200} /* Optionally enforce minimum word count in validation */
                    required
                  ></textarea>
                  <div className="w-full flex justify-end text-xs text-slate-300 font-semibold tracking-wide "> {wordCount} {wordCount<=199 ? "/ 200" : "words"}</div>

                  {/* <input type="text" className='rounded-sm outline-none px-2 py-1 border' placeholder='Highlight (optional)' name='highlight' value={placeDesc.highlight} onChange={handlePlaceDescChange} />
                  <input type="text" className='rounded-sm outline-none px-2 py-1 border' placeholder='Highlight Description (optional)' name='h_description' value={placeDesc.h_description} onChange={handlePlaceDescChange} /> */}
                </div>
              </div>

              <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>
                Set a price for your place <span className="imp">*</span>
              </p>
              <div className='flex flex-col p-2 sm:p-6'>
                <input type="number" min={0} className='rounded-sm outline-none px-2 py-1 border' placeholder='Price per night in Rs.' name='price' value={placeDesc.price} onChange={handlePlaceDescChange} />
              </div>
            </div>

            {/* STEP 2 Next Buttons */}
            <div className="flex justify-between px-4 py-4">
              <button type="button" className="bg-slate-700 text-white py-2 px-3 rounded hover:bg-slate-800 transition duration-200" onClick={() => setStep(1)}>Go Back</button>
              <button type="submit" className="bg-slate-700 text-white py-2 px-3 rounded hover:bg-slate-800 transition duration-200">
                {step2data !== undefined ? "Update Listing" : "Publish Listing"}
              </button>
            </div>
          </div>
        </form >
      </div >
    </>
  );
};


export default GCL2;
