import {  useRef } from "react";
//import { ThemeContext } from "../../src/context/ThemeContext.jsx";
import { MdOutlineGridView } from "react-icons/md";
import { FaTableList } from "react-icons/fa6";
import { FaPeopleArrows } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { MdOutlinePendingActions } from "react-icons/md";
import { BiSolidUserDetail } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import './Sidebar.css';
//import {SidebarContext}  from "../../src/context/SidebarContext.jsx"
// import { IoMdClose } from "react-icons/io";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { useLogout } from "../../../Helper/LogoutHelper";
import { useSelector } from "react-redux";

const Sidebar = () => {
  // const { theme } = useContext(ThemeContext);
  //  const {  toggleSidebar, closeSidebar } = useContext(SidebarContext);
  // const [open, setOpen] = useState(true); //GTM
  const open = true;
  const navbarRef = useRef(null);
  const location = useLocation();

  const token = useSelector((state) => state.token);
  const model = useSelector((state) => state.modelType);
  // const handleClickOutside = (event) => {
  //   if (
  //     navbarRef.current &&
  //     !navbarRef.current.contains(event.target) &&
  //     event.target.className !== "sidebar-open-btn"
  //   ) {
  //     // closeSidebar();
  //     setOpen(false); //GTM
  //     console.log('SideBar Closed')
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  
  //================ ADMIN LOGOUT GTM ==================
  const logout = useLogout();

  const handleLogout = () => {
      const modelType = model; 
      const tokenValue = token;

      logout(modelType, tokenValue);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav
      //  className={`sidebar ${open ? "sidebar-show" : "!border !border-red-500"}`}
      className={`sidebar !min-w-16 ${open ? "w-full" : "!w-16"}`}
      ref={navbarRef}
    >
      <div className="sidebar-top">
        <div className="sidebar-brand">
          <span className="sidebar-brand-logo">
            <img src="/logo3.png" className="" alt="TripItToday Logo"></img>
          </span>
        </div>
        {/* <button className="sidebar-close-btn !p-0 " 
        onClick={()=>setOpen(!open)}
        >
          <IoMdClose className="!text-black" />
        </button> */}
      </div>
      <div className="sidebar-body">
        <div className="sidebar-menu">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/admin/dashboard" className={`menu-link ${isActive('/admin/dashboard') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <MdOutlineGridView />
                </span>
                {open && <span className="menu-link-text ">Dashboard</span>}
              </Link>
            </li>

            <li className="menu-item">
              <Link to="/admin/dashboard/chart" className={`menu-link ${isActive('/admin/dashboard/chart') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <FaChartBar />
                </span>
                {open && <span className="menu-link-text">Chart</span>}
              </Link>
            </li>

            <li className="menu-item">
              <Link to="/admin/dashboard/agents" className={`menu-link ${isActive('/admin/dashboard/agents') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <FaTableList />
                </span>
                {open && <span className="menu-link-text">Agents Details</span>}
              </Link>
            </li>
            {/* -----PAYMENTS - GTM------ */}
            <li className="menu-item">
              <Link to="/admin/all-payments" className={`menu-link ${isActive('/admin/all-payments') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <FaMoneyCheckAlt />
                </span>
                {open && <span className="menu-link-text">Payments</span>}
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/pending-listings" className={`menu-link ${isActive('/admin/pending-listings') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <MdOutlinePendingActions />
                </span>
                {open && <span className="menu-link-text">Pending Listings</span>}
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/all-hosts" className={`menu-link ${isActive('/admin/all-hosts') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <BiSolidUserDetail />
                </span>
                {open && <span className="menu-link-text">Hosts</span>}
              </Link>
            </li>

          </ul>
        </div>

        <div className="sidebar-menu sidebar-menu2">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/admin/dashboard/settings" className={`menu-link ${isActive('/admin/dashboard/settings') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <IoMdSettings />
                </span>
                {open && <span className="menu-link-text">Settings</span>}
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/dashboard/help-support" className={`menu-link ${isActive('/admin/dashboard/help-support') ? 'active' : ''}`}>
                <span className="menu-link-icon">
                  <FaPeopleArrows />
                </span>
                {open && <span className="menu-link-text">Help and Support</span>}
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin" className={`menu-link ${isActive('/admin') ? 'active' : ''}`} onClick={handleLogout}>
                <span className="menu-link-icon">
                <FaSignOutAlt /> 
                </span>
                {open && <span className="menu-link-text text-red-400">Log Out </span>}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;