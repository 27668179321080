import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import CRMHeader from './CRMHeader';
import Sidebar from './CRMSidebar';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { IoCloseSharp } from "react-icons/io5";

const Offers = () => {
  const token = useSelector((state) => state.token);
  const [offers, setOffers] = useState([]);
  const [history, setHistory] = useState([]);
  const [filter, setFilter] = useState('all-offers');
  const [selectedAction, setSelectedAction] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateAction, setUpdateAction] = useState("");
  const [updateReason, setUpdateReason] = useState("");
  const [oldCallback, setOldCallback] = useState("");
  const [updateCallback, setUpdateCallback] = useState("");
  const [offerID, setOfferID] = useState("");
  const navigate = useNavigate();

  const fetchOffers = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/offers/${filter}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        credentials: "include",
      });

      const data = await response.json();
      // console.log("Offers details: ", data?.offers)
      if (filter === 'all-offers') {
        setOffers(data.offers);
      } else if (filter === 'pending-offers') {
        setOffers(data.pendingOffers);
      } else if (filter === 'rejected-offers') {
        setOffers(data.rejectedOffers);
      } else {
        setOffers(data.expiredOffers);
      }
    } catch (error) {
      toast.error("Error fetching offers");
      console.error("Error fetching offers:", error);
    }
  }, [filter, token]);

  const fetchHistory = useCallback(async (hostId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/agents/host-history/${hostId}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        credentials: "include",
      });
      const data = await response.json();
      setHistory(data?.data);
    } catch (error) {
      toast.error("Error fetching host history");
      console.error("Error fetching host history:", error);
    }
  }, [token]);

  const sendMail = async (offerId, reminderNumber) => {
    // console.log("Send Mail Reminder : ", reminderNumber, typeof reminderNumber);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/offers/send-reminder-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        credentials: "include",
        body: JSON.stringify({ offerId, reminderNumber }),
      });


      const data = await response.json();
      // console.log("Send Mail data : ", data, "\nNumber of reminders sent : ", JSON.stringify({ offerId, reminderNumber }));
      if (data.success) {
        toast.success(`${reminderNumber==="4" ? "Last Reminder Mail sent successfully!" : `Reminder #${reminderNumber} Mail sent successfully!`}`);
      } else {
        toast.error("Error sending reminder");
      }
    } catch (error) {
      toast.error("Error sending reminder");
      console.error("Error sending reminder:", error);
    }
  };

  const handleUpdateData = (action, offerId, callback) => {
    action === "Reject" ? setUpdateAction("rejected")
      : action === "PAID" ? setUpdateAction("paid")
        : action === "PO Callback" ? setUpdateAction("pending")
          : setUpdateAction("DND");
    setOfferID(offerId);
    setOldCallback(callback);
    setOpenUpdate(true);
  };

  const handleUpdate = async () => {
    let callbackToSend = updateCallback;

    if (updateAction !== "pending" && !updateCallback) {
      callbackToSend = oldCallback;
    }

    // console.log("old callback -", oldCallback, "\nnew callback -", updateCallback);
    // console.log("callback to send:", callbackToSend);
    // console.log("Offer ID:", offerID, "\nAction:", updateAction, "\nReason:", updateReason, "\nCallback:", callbackToSend ? new Date(callbackToSend).toISOString() : null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/offers/update-offer/${offerID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        credentials: "include",
        body: JSON.stringify({
          offerStatus: updateAction,
          offerStatusReason: updateReason,
          callBackTime: callbackToSend ? new Date(callbackToSend).toISOString() : null
        })
      });

      const data = await response.json();
      if (data.success) {
        toast.success("Offer updated successfully");
        fetchOffers();
        handleCloseUpdate();
      } else {
        toast.error("Error updating offer");
      }
    } catch (error) {
      toast.error("Error updating offer");
      console.error("Error updating offer:", error);
    }
  };

  const handleAction = (offerId, hostId, action, callback) => {
    const updatedSelectedAction = { ...selectedAction, [offerId]: action };
    setSelectedAction(updatedSelectedAction);

    if (action === "History") {
      fetchHistory(hostId);
    } else if (action === "Send R-1") {
      sendMail(offerId, "1");
    } else if (action === "Send R-2") {
      sendMail(offerId, "2");
    } else if (action === "Send R-3") {
      sendMail(offerId, "3");
    } else if (action === "Send RL") {
      sendMail(offerId, "4");
    } else if (action === "Reject" || action === "DND" || action === "PAID" || action === "PO Callback") {
      handleUpdateData(action, offerId, callback);
    } 
    // else if (action === "Pay Now") {
    //   console.log("Pay Now");
    //   setOpenUpdate(true);
    // }

  };

  const handleCloseHistory = () => {
    setHistory([]);
    setSelectedAction({});
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setSelectedAction({});
    setUpdateAction("");
    setUpdateReason("");
    setUpdateCallback("");
    setOldCallback("");
    setOfferID("");
  }

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <>
      <CRMHeader />
      <Sidebar />
      <div className='relative h-full bg-slate-100 ml-16 z-50'>
        <div className="p-6 flex flex-col items-center justify-center">
          <div className="flex justify-center space-x-0 mb-4">
            <button
              className={`py-1 px-3 rounded-l-md rounded-none text-sm font-semibold  ${filter === 'all-offers' ? 'bg-slate-700 text-white' : 'bg-gray-300'}`}
              onClick={() => setFilter('all-offers')}
            >
              All Offers
            </button>
            <button
              className={`rounded-none py-1 px-3 text-sm font-semibold ${filter === 'pending-offers' ? 'bg-slate-700 text-white' : 'bg-gray-300'}`}
              onClick={() => setFilter('pending-offers')}
            >
              Pending Offers
            </button>
            <button
              className={`rounded-none py-1 px-3 text-sm font-semibold ${filter === 'rejected-offers' ? 'bg-slate-700 text-white' : 'bg-gray-300'}`}
              onClick={() => setFilter('rejected-offers')}
            >
              Rejected Offers
            </button>
            <button
              className={`py-1 px-3 text-sm rounded-none rounded-r-md font-semibold ${filter === 'expired-offers' ? 'bg-slate-700 text-white' : 'bg-gray-300'}`}
              onClick={() => setFilter('expired-offers')}
            >
              Expired Offers
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white text-sm">
              <thead className=''>
                <tr className='rounded-t-md'>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Property</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Host Detail</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Plan</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Details</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Price</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Quantity</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Status</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Created At</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Expiry Date</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Callback Time</th>
                  <th className="py-2 bg-slate-200 px-3 text-sm">Actions</th>
                </tr>
              </thead>
              <tbody className='rounded-b-md'>
                {offers?.map((offer) => (
                  <tr key={offer?._id} className='border-b last:rounded-b-md'>
                    <td className="py-1 px-3 font-semibold text-xs text-center">{offer?.propertyURL}</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center cursor-pointer text-blue-600" onClick={() => navigate(`/CRM/lead-details/${offer?.hostId}`)}>Host</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">{offer?.membershipPlan}</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">{offer?.planDetail}</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">{offer?.price}</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">{offer?.quantity}</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">{offer?.offerStatus}</td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">
                      {offer?.createdAt && format(new Date(offer?.createdAt), 'dd-MM-yyyy HH:mm')}
                    </td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">
                      {offer?.expiryDate && format(new Date(offer?.expiryDate), 'dd-MM-yyyy HH:mm')}
                    </td>
                    <td className="py-1 px-3 font-semibold text-xs text-center">
                      {offer?.callBackTime && format(new Date(offer?.callBackTime), 'dd-MM-yyyy HH:mm')}
                    </td>
                    <td className="py-1 px-3 font-semibold text-xs text-center relative">
                      <select
                        className="bg-gray-100 text-sm rounded-md p-2 cursor-pointer outline-none"
                        onChange={(e) => handleAction(offer?._id, offer?.hostId, e.target.value, offer?.callBackTime)}
                        value={selectedAction[offer?._id] || ""}
                      >
                        <option value="" disabled hidden>Select Action</option>
                        <option value="History">History</option>
                        <option value="PO Callback">PO Callback</option>
                        <option value="Send R-1">Send R-1</option>
                        <option value="Send R-2">Send R-2</option>
                        <option value="Send R-3">Send R-3</option>
                        <option value="Send RL">Send RL</option>
                        <option value="Reject">Reject</option>
                        {/* <option value="Pay Now">Pay Now</option> */}
                        <option value="DND">DND</option>
                        <option value="PAID">PAID</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {history && history.length > 0 && (
            <div className="absolute h-full w-full bg-black/40 flex justify-center items-center" onClick={handleCloseHistory}>
              <div className="relative w-[700px] min-h-auto max-h-[500px] bg-white rounded-lg px-4 py-6" onClick={(e) => e.stopPropagation()}>
                <div className={`${history?.length > 3 && "overflow-y-scroll"} w-full h-[400px] grid grid-cols-1 gap-3 p-2`}>
                  {history?.map((event, index) => (
                    <div key={index} className="p-4 w-full h-full border-b bg-gray-200 rounded-md shadow-md text-black border-gray-200 flex flex-col justify-start items-start">
                      <p className='font-bold text-lg text-center h-8 mb-2 border-b pb-2'>{event?.action}</p>
                      <span className='flex items-center font-semibold  text-slate-700 gap-2 pb-1'>
                        <span><AiOutlineQuestionCircle className='!text-lg' /></span> {event?.reason}
                      </span>
                      <span className='flex items-center font-semibold text-slate-700  gap-2 pb-1'>
                        <span><CiCalendarDate className='!text-lg font-extrabold' /></span> {format(new Date(event?.date), "dd-MM-yyyy")}
                      </span>
                      <span className='flex items-center font-semibold text-slate-700 gap-2 pb-1'>
                        <span><CiClock2 className='!text-lg font-extrabold' /></span> {format(new Date(event?.date), "HH:mm")}
                      </span>
                    </div>
                  ))}
                  <div className='absolute -top-2 -right-2 bg-white hover:text-red-500 p-2 flex justify-center items-center h-8 w-8 shadow-md rounded-full cursor-pointer' onClick={handleCloseHistory}>
                    <IoCloseSharp />
                  </div>
                </div>
              </div>
            </div>
          )}

          {openUpdate && (
            <div className="absolute h-full w-full bg-black/40 flex justify-center items-center" onClick={handleCloseUpdate}>
              <div className="relative w-[450px] bg-white rounded-lg px-4 py-6" onClick={(e) => e.stopPropagation()}>
                <div className={` w-full  flex flex-col gap-1 p-2`}>
                  {/* <div className="text-lg w-full text-center m-2 font-bold text-slate-800">{updateAction}</div> */}
                  <div className="w-full p-2 flex flex-col justify-center gap-1">
                    <label htmlFor="action" className='text-sm text-slate-700 font-semibold'> Updating Offer Status to :</label>
                    <input type="text" name='action' value={updateAction} className={`rounded py-1.5 px-2 border `} disabled={true} required />
                  </div>
                  <div className="w-full p-2 flex flex-col justify-center gap-1">
                    <label htmlFor="reason" className='text-sm text-slate-700 font-semibold'> Reason :</label>
                    <input type="text" name='reason' value={updateReason} onChange={(e) => setUpdateReason(e.target.value)} className={`rounded py-1.5 px-2 border outline-none`} required />
                  </div>
                  {updateAction === "pending" && (
                    <div className="w-full p-2 flex flex-col justify-center gap-1">
                      <label htmlFor="callback" className='text-sm text-slate-700 font-semibold'> Callback :</label>
                     
                      <div className="flex flex-col space-y-2 h-full" >
                        <DatePicker
                          selected={updateCallback ? new Date(updateCallback) : null}
                          onChange={(date) => setUpdateCallback(date)}
                          showTimeSelect
                          dateFormat="Pp"
                          className="!h-full w-full rounded py-1.5 px-2 border outline-none"
                          placeholderText="Select date and time"
                          required
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-full flex justify-center items-center p-2 mt-3">
                    <button
                      className={`${(updateReason === "" || (updateAction === "pending" && !updateCallback))
                          ? "bg-gray-500 cursor-not-allowed": "bg-slate-800 hover:bg-slate-700 cursor-pointer"} text-lg duration-500 text-white w-full py-1.5 px-6 rounded tracking-wide font-medium `}
                      disabled={updateReason === "" || (updateAction === "pending" && !updateCallback)}
                      title={(updateReason === "" || (updateAction === "pending" && !updateCallback)) ? "All the fields should be filled first!" : "Update Offer Status"}
                      onClick={() => handleUpdate()}
                    >
                      Update
                    </button>
                  </div>

                  <div className='absolute -top-2 -right-2 bg-white hover:text-red-500 p-2 flex justify-center items-center h-8 w-8 shadow-md rounded-full cursor-pointer' onClick={handleCloseUpdate}>
                    <IoCloseSharp />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Offers;
