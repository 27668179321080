import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setSubscriptions, setTripList } from '../redux/state';
import { format } from 'date-fns';
import Header from '../components/Header2';
import Footer from '../components/GFOOTER';

const PaymentSuccess = () => {
  const [paymentData, setPaymentData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const id = useSelector((state) => state?.user?._id);
  const email = useSelector((state) => state?.user?.email);
  const role = useSelector((state) => state?.modelType);
  //console.log("id and role for payment", id, role);

  const capturePayment = useCallback(async (paymentId, payerId, reservation_ID, planName) => {
    console.log("planName: ",planName);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/capture-order${role === "user" ? `?reservationId=${reservation_ID}` : `?planName=${planName}`}`, { // Adjust the API endpoint as needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, email, role, paymentId, payerId }),
      });

      const data = await response.json();
      if (response.ok) {
       // console.log('Payment captured successfully:', data?.data?.data);
      //  console.log(data?.data?.paymentDetails);
        setPaymentData(data?.data?.paymentDetails);
        if(role==="host"){
          dispatch(setSubscriptions([
            ...user?.subscriptions,
            data?.data?.data
          ])); 
        }else if(role==="user"){
          dispatch(setTripList([
            ...user?.tripList,
            data?.data?.data
          ]));
        }
        
      //  console.log('Payment Data:', paymentData, "subscriptions: ",role==="host" ? user?.subscriptions : user?.TripList); 

        // Handle success (e.g., show a confirmation message)
      } else {
        console.error('Payment capture failed:', data);
        // Handle error (e.g., show an error message)
      }
    } catch (error) {
      console.error('An error occurred while capturing the payment:', error);
      // Handle network error
    }
  }, [dispatch, id, email, role, user?.subscriptions, user?.tripList]);

  useEffect(() => {
    // Extract query parameters from URL
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get('paymentId');
    const payerId = queryParams.get('PayerID');
    const planName = queryParams.get('planName');
    const reservation_ID = queryParams.get('reservationId');
   // console.log('Payment ID:', paymentId, '\nPayer ID:', payerId, '\nReservation ID:', reservation_ID, '\nplanName: ',planName);

    if (paymentId && payerId) {
      // Call your backend to capture the payment
      role === "user" ? capturePayment(paymentId, payerId, reservation_ID) : capturePayment(paymentId, payerId, "", planName);
    } else {
      console.error('Payment ID or Payer ID is missing.');
    }
  }, [ location?.search, capturePayment,  role]);

  

  return (
    <>
      <Header />
      <div className=" min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <div className="bg-white rounded-lg shadow-md w-full max-w-sm overflow-hidden ">

          <h2 className="text-2xl !m-0  font-semibold text-center !mb-4 bg-green-400 text-white p-2">Payment Successful</h2>
          <div className="flex justify-center pt-2 mb-3">
            <img src="/assets/success.gif" alt="payment" className="mx-auto w-20 h-20 mb-4  bg-white" />
          </div>
          <p className="text-center text-lg font-semibold mb-2">Thank you for your payment!</p>
          <div className='px-5'>
            <div className="text-sm font-semibold mb-2 mt-10 border-b w-full">Transaction Details</div>

            <div className='w-full flex justify-between items-center text-slate-800'>
              <div className="text-center text-xs font-semibold ">Transaction ID</div>
              <div className="text-center text-xs mb-1 font-semibold  ">{paymentData?.paymentId}</div>
            </div>
            <div className='w-full flex justify-between items-center text-slate-800'>
              <div className="text-center text-xs font-semibold ">Amount</div>
              <div className="text-center text-xs mb-1 font-semibold ">{paymentData?.amount} USD</div>
            </div>
            <div className='w-full flex justify-between items-center text-slate-800'>
              <div className="text-center text-xs font-semibold "> {role === "user" ? "User ID" : "Host ID"} </div>
              <div className="text-center text-xs mb-1 font-semibold "> {role === "user" ? paymentData?.userId : paymentData?.hostId} </div>
            </div>
            <div className='w-full flex justify-between items-center text-slate-800'>
              <div className="text-center text-xs font-semibold "> {role === "user" ? "User Email" : "Host Email"}</div>
              <div className="text-center text-xs mb-1 font-semibold ">{paymentData?.email}</div>
            </div>
            <div className='w-full flex justify-between items-center text-slate-800'>
              <div className="text-center text-xs font-semibold ">Payment Time</div>
              <div className="text-center text-xs mb-1 font-semibold ">{paymentData?.createdAt && format(new Date(paymentData?.createdAt), 'dd/MM/yyyy, HH:mm:ss')}</div>

            </div>
          </div>
         
         { role === "user" ?
            <div className='cursor-pointer mt-4 w-full  text-center font-semibold hover:text-blue-900 text-blue-950 pt-4 pb-2'
              onClick={()=> { navigate(`/${id}/trips`)}      }
            >
              Your trip is booked. Click here to view your trips
              </div>
              :
            <div className='cursor-pointer mt-4 w-full  text-center font-semibold hover:text-blue-900 text-blue-950 pt-4 pb-2'
              onClick={()=> {navigate('/create-listing' , { state: { paymentDone: true }})}      }
            >
              Start creating your listing now. Click Here 
              </div>

         }
          
        </div>



      </div>
      <Footer />
    </>
  );
};

export default PaymentSuccess;
