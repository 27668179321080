import { useState, useEffect } from 'react';
import { BiSolidCheckSquare } from "react-icons/bi";
import { categories, types } from '../data/data';
import Counter from './Counter';

const GCL1 = ({ formData, setFormData, handleNextStep, step1data , subID}) => {
    const [isFormValid, setIsFormValid] = useState(false);

    // Initialize formData with step1data when available
    useEffect(() => {
        console.log("Step1data → :", step1data);
        if (step1data) {
            setFormData((prevData) => ({
                ...prevData ,
                category: step1data.category || [],
                type: step1data.type || "",
                streetAddress: step1data.streetAddress || "",
                aptSuite: step1data.aptSuite || "",
                city: step1data.city || "",
                province: step1data.province || "",
                country: step1data.country || "",
                guestCount: step1data.guestCount || 0,
                bedroomCount: step1data.bedroomCount || 0,
                bedCount: step1data.bedCount || 0,
                bathroomCount: step1data.bathroomCount || 0,

            }));
        }
        //console.log("Step1data bedCount:", step1data.bedCount); // Log step1data.bedCount
        //console.log("(1 useEffect) formData: ", formData);
    }, []);

    // useEffect(() => {
    //     console.log("(GCL1) FormData → :", formData);
    // }, [formData]); 

    useEffect(() => {
        const { category, type, streetAddress, city, province, country, guestCount, bedroomCount, bedCount, bathroomCount } = formData;
        const allFieldsFilled = category.length > 0 && type && streetAddress && city && province && country && (guestCount !== 0 && guestCount !== undefined ) && bedroomCount!==0 && bedroomCount !== undefined && bedCount!==0 && bedCount !== undefined && bathroomCount!==0 && bathroomCount !== undefined && subID !==null && subID !== undefined;
        setIsFormValid(allFieldsFilled);
        // console.log("isFormValid → :", isFormValid);
        //console.log("FormData bedCount after allFieldsFilled:", bedCount); // Log the new bed count
    }, [formData ]);

    // Initialize selected categories and types from formData or step1data
    const [categDivs, setCategDivs] = useState(step1data?.category || []);
    const [typesDivs, setTypesDivs] = useState( step1data?.type || "");

    // Category selection
    const handleCatgClick = (label) => {
        const updatedCategDivs = [label];
    
        setCategDivs(updatedCategDivs);
    
        setFormData((prev) => ({
            ...prev,
            category: updatedCategDivs 
        }));

    };
    

    // Handle type selection
    const handleTypeClick = (name) => {
        const updatedType = typesDivs === name ? '' : name;
        setTypesDivs(updatedType);

        setFormData((prev) => ({
            ...prev,
            type: updatedType
        }));
    };

    // Handle location changes
    const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle guest, bedroom, bed, and bathroom counts
    const countGuests = (val) => {
        // Only update formData if there's an actual change in guestCount
        setFormData((prev) => {
            if (prev.guestCount !== val) {
                return { ...prev, guestCount: val };
            }
            return prev; // No update if the value hasn't changed
        });
    };
      
    const countBedrooms = (val) => {
        setFormData((prev) => {
            if (prev.bedroomCount !== val) {
                return { ...prev, bedroomCount: val };
            }
            return prev; // No update if the value hasn't changed
        });
    };
    
    const countBeds = (val) => {
        setFormData((prev) => {
            if (prev.bedCount !== val) {
               // console.log("FormData bedCount updated from", prev.bedCount, "to", val); // Log the change
                return { ...prev, bedCount: val };
            }
            return prev; 
        });
    };
    
    const countBathrooms = (val) => {
        setFormData((prev) => {
            if (prev.bathroomCount !== val) {
                return { ...prev, bathroomCount: val };
            }
            return prev; 
        });
    };
    

    return (
        <>
            <div className="py-4">
                <form onSubmit={(e) => { e.preventDefault(); handleNextStep(); }} >
                    <div>
                        <div className='text-slate-700 font-bold text-md sm:text-md md:text-lg lg:text-lg tracking-wider border-b border-slate-700 py-2 w-full '>STEP 1  |  TELL US ABOUT YOUR PLACE   <span className="absolute right-0  font-bold w-full text-end text-gray-700 text-xs p-2 pr-0 my-3 sm:my-0 "> (All fields marked with <span className="imp">*</span> are required) </span></div>


                        <div className="rounded-md bg-white/20 py-4 px-2 sm:px-2 md:px-4 lg:px-6 mt-2 flex flex-col gap-4 ">
                            <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>
                                Which of the following categories best describes your place? <span className="imp">*</span>
                            </p>
                            <div className='flex flex-wrap gap-3 justify-center px-4 sm:px-6 md:px-10 lg:px-12'>
                                {categories?.slice(1).map((category, index) => (
                                    <div
                                        key={index}
                                        className={`relative flex items-center justify-center h-16 w-20 lg:h-20 lg:w-24 p-1.5 rounded-lg cursor-pointer border hover:shadow-md ${categDivs.includes(category.label) ? 'border-2 border-slate-700 ' : ''
                                            }`}
                                        onClick={() => handleCatgClick(category.label)}
                                    >
                                        <label htmlFor={category.label} className={`flex flex-col items-center justify-center gap-2 cursor-pointer`}>
                                            <div className={`text-xl text-gray-700 cursor-pointer`}>{category.icon}</div>
                                            <p className={`text-xs font-semibold md:font-bold lg:font-bold text-center text-gray-700 cursor-pointer ${index === 0 ? 'text-slate-700' : ''}`}>
                                                {category.label}
                                            </p>
                                        </label>
                                        {categDivs.includes(category.label) && (
                                            <div className='absolute top-1 right-1'>
                                                <BiSolidCheckSquare className='text-slate-700 h-5 w-5' />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>What type of place will guests have? <span className="imp">*</span> </p>
                            <div className='flex flex-col items-center px-2 md:px-6 lg:px-8 mt-4 gap-4'>
                                {types?.map((type, index) => (
                                    <div className={`border rounded-md w-[300px] sm:w-[400px] md:w-[500px] lg:w-[650px] bg-gray-100/[0.3] hover:border-slate-700 flex items-center gap-2 md:gap-4 p-3 md:p-2 cursor-pointer hover:shadow-md ${typesDivs === type.name ? 'bg-white  border-slate-700' : ''}`} key={index} onClick={() => handleTypeClick(type.name)}>
                                        <div className='text-xl h-12 flex items-center justify-center px-2.5 sm:px-3 md:px-4 lg:px-6 text-gray-700 cursor-pointer'>
                                            {type.icon}
                                        </div>
                                        <div className='flex flex-col h-12 items-start justify-center'>
                                            <p className='font-bold text-slate-700 text-xs md:text-sm lg:text-sm tracking-wider cursor-pointer'>{type.name}</p>
                                            <p className='font-medium text-xs text-justify md:text-sm lg:text-sm text-gray-700 cursor-pointer'>{type.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>Where's your place located? </p>
                            <div className='flex flex-col py-4 px-2 sm:px-16 md:px-20 lg:px-24 gap-4'>
                                <label htmlFor="street" className='flex flex-col text-sm sm:text-sm md:text-md lg:text-md font-semibold text-gray-700 tracking-wide'>
                                    <p>Street Address <span className="imp">*</span> </p>
                                    <input type="text" className='border focus:border-slate-700 outline-none py-1.5 px-3 rounded-md' name="streetAddress" value={formData.streetAddress} onChange={handleLocationChange} />
                                </label>

                                <div className='flex flex-col lg:flex-row justify-between gap-2'>
                                    <label htmlFor="aptSuite" className='flex flex-col text-sm sm:text-sm md:text-md lg:text-md font-semibold text-gray-700 tracking-wide w-full'>
                                        <p>Apt/Suite <span className="imp">*</span> </p>
                                        <input type="text" className='border focus:border-slate-700 outline-none py-1.5 px-3 rounded-md' name="aptSuite" value={formData.aptSuite} onChange={handleLocationChange} />
                                    </label>
                                    <label htmlFor="city" className='flex flex-col text-sm sm:text-sm md:text-md lg:text-md font-semibold text-gray-700 tracking-wide w-full'>
                                        <p>City <span className="imp">*</span> </p>
                                        <input type="text" className='border focus:border-slate-700 outline-none py-1.5 px-3 rounded-md' name="city" value={formData.city} onChange={handleLocationChange} />
                                    </label>
                                </div>

                                <div className='flex flex-col lg:flex-row justify-between gap-2'>
                                    <label htmlFor="province" className='flex flex-col text-sm sm:text-sm md:text-md lg:text-md font-semibold text-gray-700 tracking-wide w-full'>
                                        <p>State/Province/Region <span className="imp">*</span> </p>
                                        <input type="text" className='border focus:border-slate-700 outline-none py-1.5 px-3 rounded-md' name="province" value={formData.province} onChange={handleLocationChange} />
                                    </label>
                                    <label htmlFor="country" className='flex flex-col text-sm sm:text-sm md:text-md lg:text-md font-semibold text-gray-700 tracking-wide w-full'>
                                        <p>Country <span className="imp">*</span> </p>
                                        <input type="text" className='border focus:border-slate-700 outline-none py-1.5 px-3 rounded-md' name="country" value={formData.country} onChange={handleLocationChange} />
                                    </label>
                                </div>
                            </div>

                            <p className='font-semibold text-sm sm:text-sm md:text-md lg:text-lg text-justify px-1 sm:pl-2 md:pl-4 lg:pl-6 mt-4 lg:mt-6 text-slate-700'>Tell us more about your place <span className="imp">*</span> </p>
                            <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-4  gap-2'>
                                <Counter For="Guests" store_val={(val) => countGuests(val)} initCount={formData.guestCount} />
                                <Counter For="Bedrooms" store_val={(val) => countBedrooms(val)} initCount={formData.bedroomCount} />
                                <Counter For="Beds" store_val={(val) => countBeds(val)} initCount={formData.bedCount} />
                                <Counter For="Bathrooms" store_val={(val) => countBathrooms(val)} initCount={formData.bathroomCount} />
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex justify-end my-6 lg:my-8 px-6 md:px-8 lg:px-12'>
                        <button type="button" className={`px-2 md:px-3 lg:px-5 py-1 ${isFormValid && "!bg-slate-700 hover:!bg-slate-800"}  bg-gray-600/80  font-medium text-xs md:text-sm lg:text-lg tracking-wider ${isFormValid ? "text-white" : "text-white/80"}  rounded outline-none ${isFormValid ? "cursor-pointer" : "cursor-not-allowed"} `} onClick={handleNextStep} disabled={!isFormValid}>Next</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default GCL1;
