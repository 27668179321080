import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setError } from './redux/state.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ScrollToTop from './components/ScrollToTop.jsx';

import { Login, RegisterPage, TripList, ReservationList, ChangePasswordPage, PropertyList, CategoryPage } from './pages';

import GHOME from "./pages/GHOME";
import GWISHLIST from "./pages/GWISHLIST";
import GCONTACTPAGE from "./pages/GCONTACTPAGE";
import GABOUT from "./pages/GABOUT";
import GWORK from "./pages/GWORK.jsx";
import GPROPERTIES from "./pages/GPROPERTIES.jsx";
import GCREATELISTING from "./pages/GCREATELISTING.jsx";
import GEDITLISTING from "./pages/GEDITLISTING.jsx";
import DeleteListing from './pages/DeleteListing.jsx';
import GPAY from "./pages/GPAY.jsx";
import PaymentSuccess from './pages/PaymentSuccess.jsx';
import SubscriptionDetails from './pages/SubscriptionsDetails.jsx';
import PaymentFail from './pages/PaymentFail.jsx';
import ECheck from './pages/ECheck.jsx';
import Dashboard from './pages/Dashboard.jsx';
import { UpdateDetails } from './pages/EditDetails.jsx';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.jsx';
import Details from './pages/Details.jsx';
import OfferPage from './pages/Offers.jsx';
import Gallery from './pages/PhotoDetails.jsx';
import TermsAndCondition from './pages/TermsAndCondition.jsx';
import FAQ from './pages/FAQ.jsx';
import CancellationPolicies from './pages/CancellationPolicies.jsx';
import { EmailVerification } from './pages/EmailVerification.jsx';
import { ConfirmBooking } from './pages/ConfirmBooking.jsx';
import { GetOTP } from './pages/GetOtpMail.jsx';
import { ChangeForgotPassword } from './pages/ForgotPassword.jsx';
import SubPlans from './pages/SubPlans.jsx';

//-----------------CRM-----------------
import CRMLogin from './CRM/CRMLogin.jsx';
// import CRMRegister from './CRM/CRMRegister.jsx';
import CRM from './CRM/CRM.jsx';
import Leads from './CRM/Leads.jsx';
import CreateOffer from './CRM/CreateOffer.jsx';
import FreshLead from './CRM/FreshLead.jsx';
import ExistLead from './CRM/ExistLead.jsx';
import UpdateAgent from './CRM/UpdateAgent.jsx';
import LeadDetails from './CRM/LeadDetails.jsx';
import Offers from './CRM/Offerss.jsx';

//-----------PAYPAL----------------
import GPLANS from './pages/GPLANS.jsx';
import GPAY_USER from './pages/GPAY_USER.jsx'

//============ A D M I N =============
import GPAYMENTS from './ADMIN/src/GPAYMENTS.jsx';
import GCREATEPLAN from './ADMIN/src/GCREATEPLAN.jsx';
import GPENDING from './ADMIN/src/GPENDING.jsx';
import GHOSTS from './ADMIN/src/GHOSTS.jsx';
import GAGENT from './ADMIN/src/GAGENT.jsx';
import GHOSTDATA from './ADMIN/src/GHOSTDATA.jsx';
//---------SK---------
import BaseLayout from './ADMIN/src/layout/BaseLayout.jsx';
import SettingPage from './ADMIN/components/setting/SettingsPage.jsx';
import HelpSupportPage from './ADMIN/components/helpsupport/HelpSupportPage.jsx';
import ReportPage from './ADMIN/components/report/ReportPage.jsx';
import Chart from './ADMIN/components/chart/Chart.jsx';
import Table from './ADMIN/components/table/Table.jsx';
import StatsTable from './ADMIN/components/stats/StatsTable.jsx';
import ManuallyTable from './ADMIN/components/stats/ManuallyTable.jsx';
import PageNotFound from './ADMIN/src/screen/error/PageNotFound.jsx';


function App() {

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user);
  const modelType = useSelector(state => state.modelType);
  //console.log("isLoggedIn - ", isLoggedIn);
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setError("You are already logged in"));
    }
  }, [isLoggedIn, dispatch]);

  return (
    <div>
      <ScrollToTop />
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={500}  // Increased timeout for smoother effect
          classNames="fade-slide"
        >
          <Routes location={location}>

            {/* NEW HOME PAGE */}
            <Route path='/' element={<GHOME />} />
            <Route path='/GHOME' element={<GHOME />} />
            <Route path='/home' element={<GHOME />} />
            <Route path='/login' element={isLoggedIn ? <Navigate to="/" /> : <Login />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/about' element={<GABOUT />} />
            <Route path='/contact' element={<GCONTACTPAGE />} />
            <Route path='/how-we-work' element={<GWORK />} />
            <Route path='/all-listings' element={<GPROPERTIES />} />
            <Route path='/gcl' element={<GCREATELISTING />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/payment' element={<GPAY />} />
            <Route path='/payment/success' element={<PaymentSuccess />} />
            <Route path='/payment/fail' element={<PaymentFail />} />
            <Route path='/echeck' element={<ECheck />} />
            <Route path='/change-password' element={<ChangePasswordPage />} />
            <Route path='/host-get-otp' element={<GetOTP />} />
            <Route path='/get-otp' element={<GetOTP />} />
            <Route path='/:userId/reset-password/:otpToken' element={<ChangeForgotPassword />} />
            <Route path='/update-details' element={<UpdateDetails />} />
            <Route path='/PrivacyPolicyPage' element={<PrivacyPolicyPage />} />
            <Route path='/Offer' element={<OfferPage />} />
            <Route path='/gallery/:listingId' element={<Gallery />} />

            {/* SEARCH */}
            {/* <Route path="/properties/search/:search" element={<SearchPage />} /> */}

            {/* AFTER LOGIN AND AUTHENTICATION */}
            <Route path="/:userId/trips" element={<TripList />} />
            <Route path="/:userId/wishList" element={<GWISHLIST />} />
            <Route path="/:userId/reservations" element={<ReservationList />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
            <Route path="/TC" element={<TermsAndCondition />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/Cp" element={<CancellationPolicies />} />
            <Route path="/verify-email/:verificationToken" element={<EmailVerification />} />

            {/* HOST */}
            <Route path='/subplans' element={<SubPlans />} />
            <Route path='/host_login' element={<Login />} />
            <Route path='/host_register' element={<RegisterPage />} />
            <Route path='/create-listing' element={<GCREATELISTING />} />
            <Route path='/edit-listing/:listingId' element={<GEDITLISTING />} />
            <Route path='/confirm-delete/:verificationToken' element={<DeleteListing />} />
            <Route path="/:userId/properties" element={<PropertyList />} />
            <Route path="/properties/:listingId" element={<Details />} />
            <Route path="/properties/category/:category" element={<CategoryPage />} />
            <Route path="/verify-booking/:id" element={<ConfirmBooking />} />
            <Route path="/subscriptions" element={<SubscriptionDetails />} />

            {/* PAYPAL */}
            <Route path='/plans' element={<GPLANS />} />
            <Route path='/user-payment' element={<GPAY_USER />} />

            {/*=========================== CRM ========================*/}
            <Route path='/CRM' element={<CRM />} />
            <Route path='/CRM/login' element={<CRMLogin />} />
            {/* <Route path='/CRM/register' element={<CRMRegister />} /> */}
            <Route path='/CRM/dashboard' element={<CRM />} />
            <Route path='/CRM/leads' element={<Leads />} />
            <Route path='/CRM/update-agent' element={<UpdateAgent />} />
            <Route path='/CRM/lead-details/:hostId' element={<LeadDetails />} />
            {/* ------------FRESH LEAD------------- */}
            <Route path='/CRM/create-offer' element={<CreateOffer />} />
            <Route path='/CRM/fresh/callback' element={<FreshLead />} />
            <Route path='/CRM/fresh/paid' element={<FreshLead />} />
            <Route path='/CRM/fresh/lang_barrier' element={<FreshLead />} />
            <Route path='/CRM/fresh/no_interest' element={<FreshLead />} />
            <Route path='/CRM/fresh/DND' element={<FreshLead />} />
            <Route path='/CRM/fresh/not_connected' element={<FreshLead />} />
            {/* -------------EXIST LEAD------------- */}
            <Route path='/CRM/exist/send-offer' element={<CreateOffer />} />
            <Route path='/CRM/exist/callback' element={<ExistLead />} />
            <Route path='/CRM/exist/paid' element={<ExistLead />} />
            <Route path='/CRM/exist/lang_barrier' element={<ExistLead />} />
            <Route path='/CRM/exist/no_interest' element={<ExistLead/>} />
            <Route path='/CRM/exist/DND' element={<ExistLead/>} />
            <Route path='/CRM/exist/not_connected' element={<ExistLead />} />

            {/* --------------------OFFERS--------------------- */}
            <Route path='/CRM/offers' element={<Offers />} />

            {/* ========================================= A D M I N ================== */}
            <Route path='/admin' element={<Login />} />
            <Route path='/admin/login' element={<Login />} />
            <Route path='/admin/all-payments' element={isLoggedIn && modelType === 'admin' ? <GPAYMENTS /> : <PageNotFound />}  />
            <Route path='/admin/create-plan' element={isLoggedIn && modelType === 'admin' ? <GCREATEPLAN /> : <PageNotFound />}  />
            <Route path='/admin/pending-listings' element={isLoggedIn && modelType === 'admin' ? <GPENDING /> : <PageNotFound />}  />
            <Route path='/admin/all-hosts' element={isLoggedIn && modelType === 'admin' ? <GHOSTS /> : <PageNotFound />}  />
            <Route path='/admin/host-details' element={isLoggedIn && modelType === 'admin' ? <GHOSTDATA /> : <PageNotFound />}  />
            <Route path='/admin/register-agent' element={isLoggedIn && modelType === 'admin' ? <GAGENT /> : <PageNotFound />}  />
            {/* --------SK------- */}
            <Route path="/admin/dashboard" element={isLoggedIn && modelType === 'admin' ? <BaseLayout /> : <PageNotFound />}>
              <Route index element={isLoggedIn && modelType === 'admin' ? <ReportPage /> : <PageNotFound />} />
              <Route path="chart" element={isLoggedIn && modelType === 'admin' ? <Chart /> : <PageNotFound />} />
              <Route path="settings" element={isLoggedIn && modelType === 'admin' ? <SettingPage /> : <PageNotFound />} />
              <Route path="help-support" element={isLoggedIn && modelType === 'admin' ? <HelpSupportPage /> : <PageNotFound />} />
              <Route path="results" element={isLoggedIn && modelType === 'admin' ? <Table /> : <PageNotFound />} />
              <Route path="agents" element={isLoggedIn && modelType === 'admin' ? <StatsTable /> : <PageNotFound />} />
              <Route path="manuallyedit" element={isLoggedIn && modelType === 'admin' ? <ManuallyTable /> : <PageNotFound />} />
            </Route>
         
           {/* -----------INVALID ROUTE------------- */}
            <Route path="*" element={<PageNotFound />} />


          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default function RootApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
