import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import SideBar from '../components/sidebar/Sidebar'
import { FaRegCheckCircle } from "react-icons/fa";
// import { toast } from 'react-toastify'
// import { format } from 'date-fns'

const GHOSTS = () => {
    const user = useSelector(state => state.user)
    const model = useSelector(state => state.modelType)
    const token = useSelector(state => state.token)
    const [hosts, setHosts] = useState([])
    const [filteredHosts, setFilteredHosts] = useState([])
    // States for each search/filter input
    const [searchName, setSearchName] = useState('')
    const [searchEmail, setSearchEmail] = useState('')
    const [searchPhone, setSearchPhone] = useState('')
    const [searchId, setSearchId] = useState('')
    const [searchPropertyTitle, setSearchPropertyTitle] = useState('')
    const [searchStatus, setSearchStatus] = useState('')

    const navigate = useNavigate()

    const getAllHosts = useCallback(async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/all-hosts-details`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            const data = await res.json()

            if (res.ok) {
                setHosts(data?.data?.data)
                setFilteredHosts(data?.data?.data) // Initialize filtered hosts
            }

        } catch (err) {
            console.log(err)
        }
    }, [token])

    useEffect(() => {
        getAllHosts()
    }, [getAllHosts])

    // Filter the hosts based on the search inputs
    useEffect(() => {
        const filtered = hosts.filter(host => {
            const matchesName = searchName ?
                `${host?.firstName} ${host?.lastName}`.toLowerCase().includes(searchName?.toLowerCase()) : true;
            const matchesEmail = searchEmail ?
                host?.email?.toLowerCase().includes(searchEmail?.toLowerCase()) : true;
            const matchesPhone = searchPhone ?
                host?.phoneNumber?.includes(searchPhone) : true;
            const matchesId = searchId ?
                host?._id?.includes(searchId) : true;
            const matchesPropertyTitle = searchPropertyTitle ?
                host?.properties?.some(property => property?.title?.toLowerCase().includes(searchPropertyTitle?.toLowerCase())) : true;
            const matchesStatus = searchStatus ?
                host?.status === searchStatus : true;

            return matchesName && matchesEmail && matchesPhone && matchesId && matchesPropertyTitle && matchesStatus;
        });
        setFilteredHosts(filtered);
    }, [searchName, searchEmail, searchPhone, searchId, searchPropertyTitle, searchStatus, hosts])

    return (
        <>
            {user && model === 'admin'
                ? (
                    <>
                        <SideBar />
                        <div className='h-full md:pl-[70px] lg:pl-[260px] w-full bg-slate-50 flex flex-col justify-center items-center py-12'>
                            <div className='w-full font-extrabold text-slate-800 text-4xl text-center tracking-wide mb-8'>
                                Hosts Details
                            </div>


                            {/* --------filters-------- */}
                            <div className='w-full mb-3 p-4 grid grid-cols-6 gap-4'>
                                <input
                                    type="text"
                                    placeholder="Host ID"
                                    value={searchId}
                                    onChange={e => setSearchId(e.target.value)}
                                    className='w-full px-2 py-1 outline-none border focus:border-slate-700   rounded'
                                />
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={searchName}
                                    onChange={e => setSearchName(e.target.value)}
                                    className='w-full  px-2 py-1 outline-none border focus:border-slate-700   rounded'
                                />
                                <input
                                    type="text"
                                    placeholder="Email"
                                    value={searchEmail}
                                    onChange={e => setSearchEmail(e.target.value)}
                                    className='w-full  px-2 py-1 outline-none border focus:border-slate-700   rounded'
                                />
                                <input
                                    type="text"
                                    placeholder="Phone"
                                    value={searchPhone}
                                    onChange={e => setSearchPhone(e.target.value)}
                                    className='w-full px-2 py-1 outline-none border focus:border-slate-700   rounded'
                                />
                                <input
                                    type="text"
                                    placeholder="Property Title"
                                    value={searchPropertyTitle}
                                    onChange={e => setSearchPropertyTitle(e.target.value)}
                                    className='w-full px-2 py-1 outline-none border focus:border-slate-700   rounded'
                                />
                                <select
                                    value={searchStatus}
                                    onChange={e => setSearchStatus(e.target.value)}
                                    className='w-full  px-2 py-1 outline-none border focus:border-slate-700   rounded'
                                >
                                    <option value="">Filter by Status</option>
                                    <option value="Subscription">Subscription</option>
                                    <option value="Commission">Commission</option>
                                </select>
                            </div>
                            <div className='w-full font-bold text-slate-800 text-sm text-end tracking-wide m-1 px-8'>
                                Total Hosts : {hosts?.length}
                            </div>

                            {/* ------- Host k divs------ */}
                            <div className='px-6 w-full'>
                                {
                                    filteredHosts?.map((host, index) => {
                                        return (
                                            <div key={index} className='relative w-full hover:cursor-pointer bg-white/50 hover:bg-white hover:shadow p-4 border rounded mb-1' onClick={() =>  navigate('/admin/host-details' , {state : {data:host}})}>
                                                {host?.interestType && <div className='absolute top-2 left-0 rounded-r-xl bg-slate-700/80 text-white px-2 py-1 shadow font-bold text-xs tracking-wide'>
                                                    {host?.interestType}
                                                </div>}
                                                <div className='grid grid-cols-5 place-content-between content-center w-full '>
                                                    {/* -------avatar------- */}
                                                    <div className='col-span-1 w-full flex flex-col items-center justify-center gap-2'>
                                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/${host?.avatar?.replace("public", "")}`} alt='host' className='w-24 h-24 rounded-full border-2' />
                                                    </div>
                                                    {/* ------personal----- */}
                                                    <div className='col-span-2 w-full flex flex-col items-center justify-start gap-1'>
                                                        <div className='font-bold text-lg text-center w-full'>Personal Details</div>
                                                        <div className='w-full pl-6 flex flex-col items-start justify-center gap-0.5 text-sm font-semibold text-slate-700'>
                                                            <div className='w-full flex items-center justify-start gap-2'>
                                                                <div>Host ID : </div>
                                                                <div className=''>{host?._id}</div>
                                                            </div>
                                                            <div className='w-full flex items-center justify-start gap-2'>
                                                                <div>Name :</div>
                                                                <div className='flex gap-1'>
                                                                    <div className=''>{host?.firstName}</div>
                                                                    <div className=''>{host?.lastName}</div>
                                                                </div>
                                                            </div>
                                                            <div className='w-full flex items-center justify-start gap-2'>
                                                                <div>Phone :</div>
                                                                <div className=''>{host?.phoneNumber}</div>
                                                            </div>
                                                            <div className='w-full flex items-center justify-start gap-2'>
                                                                <div>Email :</div>
                                                                <div className='flex items-center gap-[2px]'>{host?.email} {host?.emailVerified && <FaRegCheckCircle className={`text-green-500`} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* -------property------- */}
                                                    <div className='col-span-2 w-full flex flex-col items-center justify-start gap-1'>
                                                        <div className='font-bold text-lg text-center w-full'>Property Details</div>
                                                        <div className='flex flex-col items-start justify-center gap-0.5'>
                                                            <div className='w-full text-xs text-center font-semibold text-slate-600'>
                                                                {host?.properties?.length > 0 ? `Total Properties : ${host?.properties?.length}` : "No Properties Listed."}
                                                            </div>
                                                        </div>
                                                        <div className={`w-full ${host?.properties?.length > 3 ? "h-14 pr-0.5 overflow-y-scroll" : ""}`}>
                                                            {
                                                                host?.properties?.map((property, index) => (
                                                                    <div className='w-full font-semibold text-sm text-slate-700 flex items-center justify-start gap-x-3' key={index}>
                                                                        <div>{index + 1}.</div>
                                                                        <div className='w-full flex items-center gap-1'>{property?.title}, {property?.country}   {property?.adminStatus === "Approved" && <FaRegCheckCircle className='text-green-500' />}</div>
                                                                        <Link to={`/properties/${property?._id}`} className='font-semibold text-blue-700'>
                                                                            View
                                                                        </Link>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>

                )
                : <></>
            }
        </>

    )
}

export default GHOSTS
