import React from 'react'
import Header2 from "../components/Header2"
import GFooter from "../components/GFOOTER"
const GWORK = () => {
  return (
    <>
    <Header2/>
    <div className='h-screen flex justify-center items-center font-bold text-5xl' >How We Work ? Coming Soon</div>
    <GFooter/>
    </>
  )
}

export default GWORK