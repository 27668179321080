import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SideBar from '../components/sidebar/Sidebar'
import { format } from 'date-fns'

const GPAYMENTS = () => {
    const user = useSelector(state => state.user)
    const model = useSelector(state => state.modelType)
    const token = useSelector(state => state.token)
    const [payments, setPayments] = useState([])

    const [paymentIdFilter, setPaymentIdFilter] = useState("")
    const [emailFilter, setEmailFilter] = useState("")
    const [amountFilter, setAmountFilter] = useState("")
    const [dateFilter, setDateFilter] = useState("")

    const navigate = useNavigate()

    const getPayments = useCallback(async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            const data = await res.json()

            if (res.ok) {
                setPayments(data?.data)
            }
            // console.log('data - ' , data.data)
            // console.log('payments - ' , payments)

        } catch (err) {
            console.log(err)
        }
    }, [token])

    useEffect(() => {
        getPayments()
    }, [getPayments])

    const filteredPayments = payments.filter(payment => {
        return (
            (payment?.paymentId?.toLowerCase().includes(paymentIdFilter?.toLowerCase())) &&
            (payment?.email?.toLowerCase().includes(emailFilter?.toLowerCase())) &&
            (amountFilter === "" || payment?.amount?.toString() === amountFilter) &&
            (dateFilter === "" || format(new Date(payment.createdAt), 'yyyy-MM-dd') === dateFilter)
        )
    })

    const isFilterEmpty = paymentIdFilter === "" && emailFilter === "" && amountFilter === "" && dateFilter === ""

    return (
        <>
            {user && model === 'admin' ? (
                 <>
                <SideBar/>
                <div className='md:pl-[70px] lg:pl-[260px] w-full bg-slate-50 flex flex-col justify-center items-center px-8 py-12'>
                    <div className='w-full font-extrabold text-slate-800 text-4xl text-center tracking-wide mb-8'>
                        Payments
                    </div>

                    <div className='w-full flex flex-wrap justify-center lg:justify-end items-center gap-1.5 mb-4'>
                        <input
                            type="text"
                            placeholder="Filter by Payment ID"
                            value={paymentIdFilter}
                            onChange={(e) => setPaymentIdFilter(e.target.value)}
                            className="outline-none  font-medium border rounded bg-white/80 focus:border-slate-600 px-2 py-1"
                        />
                        <input
                            type="text"
                            placeholder="Filter by Email"
                            value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)}
                            className="outline-none font-medium border rounded bg-white/80 focus:border-slate-600 px-2 py-1"
                        />
                        <input
                            type="number"
                            placeholder="Filter by Amount"
                            value={amountFilter}
                            onChange={(e) => setAmountFilter(e.target.value)}
                            className="outline-none font-medium border rounded bg-white/80 focus:border-slate-600 px-2 py-1"
                        />
                        <input
                            type="date"
                            placeholder="Filter by Date"
                            value={dateFilter}
                            onChange={(e) => setDateFilter(e.target.value)}
                            className="outline-none font-medium border rounded bg-white/80 focus:border-slate-600 px-2 py-1"
                        />
                    </div>

                    <div className='pl-5 flex flex-col w-full items-center justify-center gap-0.5'>
                        <div className='w-full p-2 grid grid-cols-7 mb-2 bg-slate-700'>
                            <div className='text-white font-bold flex justify-center items-center'>S.No.</div>
                            <div className='text-white font-bold col-span-2 flex justify-center items-center'>Transaction ID</div>
                            <div className='text-white font-bold col-span-2 flex justify-center items-center'>Payer Email</div>
                            <div className='text-white font-bold flex justify-center items-center'>Amount</div>
                            <div className='text-white font-bold flex justify-center items-center'>Date & Time</div>
                        </div>

                        {(isFilterEmpty ? payments : filteredPayments)?.map((payment, index) => (
                            <div className='w-full p-2 odd:bg-slate-200 even:bg-slate-100 hover:bg-white grid grid-cols-7' key={payment._id}>
                                <div className='text-xs lg:text-sm font-medium flex justify-center items-center'>{index + 1}</div>
                                <div className='text-xs lg:text-sm font-medium col-span-2 flex justify-center items-center'>{payment?.paymentId}</div>
                                <div className='text-xs lg:text-sm font-medium col-span-2 flex justify-center items-center'>{payment?.email}</div>
                                <div className='text-xs lg:text-sm font-medium flex justify-center items-center'>{payment?.amount}</div>
                                <div className='text-xs lg:text-sm font-medium flex justify-center items-center'>
                                    {format(new Date(payment?.createdAt), 'dd/MM/yyyy, HH:mm:ss')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </>
            ) : (
                <div>Not Authorized</div>
            )}
        </>
    )
}

export default GPAYMENTS
