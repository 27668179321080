import { useCallback, useEffect, useState } from "react";
import Header from "../components/Header2";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ListingCard from "../components/GCARD";
import Footer from "../components/GFOOTER";
import { setTripList, setLogin } from "../redux/state";
import { toast } from "react-toastify";
import { isTokenExpired } from "../utility/CheckToken";
import { FiCheckCircle } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";

export const TripList = () => {
  const tripList = useSelector((state) => state.user?.tripList) || [];
  console.log("(Triplist.jsx 16) Redux TripList - ", tripList);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const model = useSelector((state) => state.modelType);
  const [confirmTripId, setConfirmTripId] = useState(null); // State to manage confirmation for a single card

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      // console.log("Token expired");
      dispatch(
        setLogin({
          user: null,
          token: null,
          modelType: null,
        })
      );
      console.log("Session expired. Please log in again.");
      model === "host" ? navigate("/host_login") : navigate("/login");
      return;
    }
  }, [token, model, dispatch, navigate]);

  const getTripList = useCallback(async () => {
    try {
      const triplistResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${model}s/triplists`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const triplistData = await triplistResponse.json();
      console.log("(Triplist.jsx 48) Triplist Data - ", triplistData?.data);
      dispatch(setTripList(triplistData?.data));
      console.log("(Triplist.jsx 51) Triplist Data - ", triplistData?.data);

    } catch (error) {
      toast.warn(`${error.message}`);
    }
  }, [token, model, dispatch]);
  useEffect(() => {
    if (token && model) {
      getTripList();
    }
  }, [token, model, dispatch, getTripList]);

  const updateTripLists = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/reservations/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      dispatch(setTripList(data?.data));

      toast.info("Reservation cancelled.");
    } catch (err) {
      toast.error(err);
    }
  };

  const handleCancelClick = (tripId) => {
    setConfirmTripId(tripId); // Set the current trip ID for confirmation
    toast.warn("Clicking YES will cancel your reservation.");
  };

  const handleNoClick = () => {
    setConfirmTripId(null); // Reset the confirmation state
  };

  const handlePayment = (property_name ,trip_id, price_per_night, total_amt, start, end) => {
    const booking_data = { 
      id: trip_id, 
      name : property_name,
      price : price_per_night,
      total: total_amt, 
      currency: "USD",
      start: start, 
      end: end 
    };

    navigate(`/user-payment`, { state: { booking: booking_data } });
    //console.log("Payment page pr swagat h")
  }
  return (
    <>
      {user && model === "user" ?
        <>
          <Header />
          <div className="bg-slate-100">
            <h1 className="text-center font-bold text-2xl sm:text-3xl py-20 mx-auto text-slate-800">
              Your Trip List
            </h1>

            {tripList?.length === 0 ? (
              <div className="h-[65vh] flex flex-col  justify-center items-center font-bold text-xl mx-auto text-slate-700 pb-10">
                No trips to display. Book your trip now. <br />
                <Link
                  to="/all-listings"
                  className={`m-1 p-1 text-black text-lg animate-pulse`}
                >
                  Explore
                </Link>
              </div>
            ) : (
              <div className="relative mx-auto w-full flex flex-wrap justify-center gap-6 pb-16">
                {console.log(tripList)}
                 {tripList?.map((trip) => (
                  <div
                    key={trip?._id}
                    className="relative flex flex-col gap-0.5 items-center justify-center"
                  >
                    <ListingCard
                      title={trip?.listing?.title}
                      listingId={trip?.listId} // Use the correct `listId` from the trip object
                      creator={trip?.listing?.hostId}
                      listingPhotoPaths={trip?.listing?.listingPhotoPaths}
                      city={trip?.listing?.city}
                      province={trip?.listing?.province}
                      country={trip?.listing?.country}
                      category={trip?.listing?.category}
                      startDate={trip?.checkIn} // Use `checkIn` from the trip object
                      endDate={trip?.checkOut} // Use `checkOut` from the trip object
                      totalPrice={trip?.listing?.price * (Math.round(new Date(trip?.checkOut) - new Date(trip?.checkIn)) / (1000 * 60 * 60 * 24))} // Use the price from the listing object
                      booking={true} // Assuming booking is true by default
                    />
                    { trip?.paid ?
                    <div className={`absolute top-2 left-0 py-1 px-2 bg-green-500 rounded-r-xl  text-white font-bold text-xs text-center tracking-wider flex justify-center items-center gap-1`}>
                      <span className="inline"><FiCheckCircle className="font-bold text-sm" /></span> 
                      Booked
                    </div>

                    :
                    <div className={`absolute top-0 p-2 w-full bg-white/80 rounded-t shadow-md ${trip?.status === "approved" && "!text-green-500 "} ${trip?.status === "rejected" && "!text-red-500  "} text-slate-800 font-semibold text-xs text-center tracking-wide flex items-center gap-1`}>
                      {trip?.status === "pending" && <span>Status :</span>}
                      {trip?.status === "rejected" && <span className="inline"><IoMdCloseCircleOutline className="font-bold text-lg" /></span>}
                      {trip?.status === "approved" && <span className="inline"><FiCheckCircle className="font-bold text-sm" /></span>} {trip?.status?.charAt(0)?.toUpperCase() + trip?.status?.slice(1)} { (trip?.status === "approved" || trip?.status==="rejected" ) && "by Host"}
                    </div>
                    }

                    <div className="w-full flex flex-col text-gray-600 hover:text-gray-800">
                      {confirmTripId === trip?._id ? (
                        <div className={`w-full flex items-center justify-between px-2 py-1`}>
                          <p className="font-semibold text-slate-800">Are you sure?</p>
                          <div className={`flex gap-x-2 md:gap-x-4`}>
                            <button
                              className={`rounded px-3 md:px-4 py-1 bg-red-500 text-white font-bold text-xs tracking-wider`}
                              onClick={() => updateTripLists(trip?._id)}
                            >
                              YES
                            </button>
                            <button
                              className={`rounded px-3 md:px-4 py-1 bg-slate-700 text-white font-bold text-xs tracking-wider`}
                              onClick={handleNoClick}
                            >
                              NO
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="w-full flex items-center justify-between gap-2 pt-1 px-[1px] ">
                            <button
                              className={`text-center text-sm font-semibold rounded text-slate-800 border border-slate-800 p-1 w-full  hover:text-red-500 cursor-pointer duration-500`}
                              onClick={() => handleCancelClick(trip?._id)}
                            >
                              Cancel Trip
                            </button>
                            {/* {trip?.status === "approved" && (  */}
                            {trip?.status === "approved" && trip?.paid===false && (
                              <button className={`text-center text-sm font-semibold text-white bg-slate-700 rounded  border border-slate-700 p-1 w-full  cursor-pointer duration-500`}
                                onClick={() => {
                                  let total = trip?.listing?.price * (Math.round(new Date(trip?.checkOut) - new Date(trip?.checkIn)) / (1000 * 60 * 60 * 24))
                                  handlePayment(trip?.listing?.title , trip?._id, trip?.listing?.price, total, trip?.checkIn, trip?.checkOut)
                                }}>
                                Pay Now
                              </button>
                              )
                            }
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Footer />
        </> :
        <>
          <Header />
          <div className="h-screen  bg-gradient-to-r from-slate-300 to-white flex flex-col justify-center items-center gap-5 tracking-wider text-lg">
            <div className='text-xl flex gap-x-6  text-slate-700 font-bold py-4'> You need to be logged in to access this  page.</div>
            <Link to="/login" className='text-lg font-semibold text-blue-700 px-1 '> Login Here</Link>
          </div>
          <Footer />

        </>}
    </>
  );
};
