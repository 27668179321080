
import { useEffect, useState, useCallback } from "react";
import { categories } from "../data/data";
import GCard from "./GCARD";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { setListings } from "../redux/state";
const Listings = () => {
//    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const token = useSelector((state) => state.token);

    const [selectedCategory, setSelectedCategory] = useState("All");

    const listings = useSelector((state) => state.listings);

    const getFeedListings = useCallback(async () => {
        try {
            const allListings  = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/properties` , {method: "GET"});
            const all = await allListings.json();
            console.log("all: ", all);
            //console.log("all: ", all.data.length);
            setMaxPage(Math.floor(all?.data?.length / 5) + 1 );
           // console.log("maxPage: ", maxPage);

            const response = await fetch(selectedCategory !== 'All'
                ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/properties/category/${selectedCategory}`
                : `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings?page=${page}&limit=5`,
                {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`, // Include token in the Authorization header
                    },
                    credentials: "include"
                }
            );

            const data = await response.json();
            //console.log("category",selectedCategory,"\ndata: ", data);
            if(selectedCategory === 'All'){
                dispatch(setListings({ listings: data?.listings }));
            }else{
                dispatch(setListings({ listings: data?.data }));
            }
       // console.log("listings: ", listings);
            setLoading(false);
        } catch (err) {
            console.log("Fetch Listings Failed", err.message);
        }
    }, [selectedCategory, page, token,  dispatch]);

    const handleMore = (x) => {
     setPage(x);
      };

    useEffect(() => {
        getFeedListings();
    }, [getFeedListings]);

    return (
        <>
         <div className="category-list p-6 md:p-16 flex flex-col justify-center flex-wrap  gap-3 sm:gap-5">
                <div className="w-full">
                    <h2 className="font-libre text-center font-bold capitalize text-4xl my-2 text-gray-600 ">Your Dream Stay Awaits</h2>
                    <p className="font-libre  text-gray-500 text-md md:text-lg  tracking-wide">Effortless Planning, Unmatched Vibes. Explore Diverse Destinations, From Beachfront Bliss to Desert.</p>
                </div>
                <br />
                <div className="flex -mx-4 -my-2 md:-mx-0 md:-my-0 md:px-2 py-4 flex-wrap justify-center gap-2 md:gap-4 ">
                    {categories?.map((category, index) => (
                        <div
                            className={`rounded px-2 py-1 gap-x-2 sm:gap-x-3 flex items-center text-slate-600 bg-white/60 hover:bg-white/80 duration-200 cursor-pointer ${category.label === selectedCategory ? "selected !bg-gray-600 !text-white" : ""}`}
                            key={index}
                            onClick={() => setSelectedCategory(category?.label)}
                        >
                            <div className="category_icon text-sm  sm:text-md">{category?.icon}</div>
                            <p className="text-xs sm:text-sm font-libre font-bold capitalize">{category?.label}</p>
                        </div>
                    ))}
                </div>
            </div>

            {loading ? (
                <Loader />
            ) : ( <>
                <div className="relative listings flex justify-center gap-y-4 md:gap-y-7 gap-x-8 md:gap-x-16 flex-wrap font-libre font-bold capitalize pb-10 md:pb-20 mx-auto md:mx-40">
                    {listings?.map(
                        ({title, _id, creator, listingPhotoPaths, city, province, country, category, type, price, booking = false }) => (

                            <GCard
                               key={_id}
                                title={title}
                                listingId={_id}
                                creator={creator}
                                listingPhotoPaths={listingPhotoPaths}
                                city={city}
                                province={province}
                                country={country}
                                category={category}
                                type={type}
                                price={price}
                                booking={booking}
                            />
                        )
                    )}

                <div className="absolute bottom-2  duration-300 ease  flex items-center justify-center w-full gap-4 ">             
                {
                     maxPage > 1  && (
                        [...Array(maxPage)].map((_,i) => (
                        <div
                            key={i + 1} 
                            className="mt-6 h-5 w-5 bg-white rounded text-slate-800 border text-xs font-extrabold shadow-xl outline-none hover:scale-[1.1] duration-150 cursor-pointer flex items-center justify-center" 
                            onClick={() => handleMore(i + 1)}
                        > {i + 1} 
                        </div>
                         ))
                        )
                }                
             </div>
                </div>
           </> )
           }
        </>
    );
};

export default Listings;