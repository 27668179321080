import { Link } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";


const Widgets = () => {
    const widgetData = [
      { title: "Offers Sent ", value: "10" },
      { title: "Total Revenue", value: "$5000" },
      { title: "New Leads", value: "5" },
      { title: "Closed Leads", value: "8" },
    ];
  
    return (
      <div className="grid grid-cols-2 gap-4">
        {widgetData.map((widget, index) => (
          <div key={index} className="bg-white/90 text-slate-700 p-3 rounded shadow hover:bg-white w-full">
            <h3 className="text-lg font-semibold">{widget.title}</h3>
            <p className="text-2xl font-bold">{widget.value}</p>
          </div>
        ))}
        <Link to="/CRM/update-agent" className="grid col-span-2 place-content-center place-items-center text-white text-xl font-bold bg-slate-700 rounded shadow w-full" >

        <div className="flex gap-x-4 text-white text-xl font-bold  w-full tracking-wider">
          {/* -----EDIT DETAILS BUTTON----- */}
                      <div className={` font-bold text-lg text-white flex justify-center items-center `}>
                        < FaUserEdit className="text-xl" />
                      </div>
                    Edit Profile
          </div>
        </Link>
      </div>
    );
  };
  
  export default Widgets;
  