import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import SideBar from '../components/sidebar/Sidebar'
import { FaAngleRight } from "react-icons/fa6";
import { FiCheckCircle } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { format } from 'date-fns'
import { toast } from 'react-toastify'

const GPENDING = () => {
    const user = useSelector(state => state.user)
    const model = useSelector(state => state.modelType)
    const token = useSelector(state => state.token)
    const [pendingListings, setPendingListings] = useState([])
    const [allListings, setAllListings] = useState([]);
    const [filteredListings, setFilteredListings] = useState([])
    const [filter, setFilter] = useState("All")
    const [hostData, setHostData] = useState({})
    const [rsn, setRsn] = useState(false)
    const [rsnData, setRsnData] = useState({})
    // const [mailData, setMailData] = useState({})
    const [reason, setReason] = useState("");
    const [customReason, setCustomReason] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const navigate = useNavigate()

    const getHostDetails = useCallback(async (hostID) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/get-host/${hostID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await res.json();
            if (res.ok) {
                setHostData((prevHostData) => ({
                    ...prevHostData,
                    [hostID]: data.data,
                }));
            } else {
                throw new Error(data.message || "Failed to fetch host details");
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const getPendingListings = useCallback(async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/pending-listings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            const data = await res.json();
            if (res.ok) {
                setPendingListings(data?.data);
                data?.data?.forEach((listing) => {
                    getHostDetails(listing?.hostId);
                });
            }
        } catch (err) {
            console.log(err);
        }
    }, [token , getHostDetails]);

    const getAllListings = useCallback(async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/properties`, {
                method: 'GET',
            });
            const data = await res.json();
            if (res.ok) {
                setAllListings(data?.data);
                data?.data?.forEach((listing) => {
                    getHostDetails(listing?.hostId);
                });
            }
        } catch (err) {
            console.log(err);
        }
    }, [ getHostDetails]);

    // Filter listings based on the current filter state
    const filterListings = useCallback(() => {
        getAllListings();
        if (filter === "Approved") {
            setFilteredListings(allListings.filter(listing => listing.adminStatus === "Approved"));
        } else if (filter === "Rejected") {
            setFilteredListings(allListings.filter(listing => listing.adminStatus === "Rejected"));
        } else {
            setFilteredListings(pendingListings);
        }
    }, [filter,allListings, getAllListings, pendingListings]);

    useEffect(() => {
        getAllListings();
    }, [getAllListings]);

    useEffect(() => {
        getPendingListings();
    }, [getPendingListings]);

    useEffect(() => {
        // console.log('allListings - ', allListings)
        // console.log('pendingListings - ', pendingListings)
        // console.log('hostData - ', hostData)
        filterListings();
    }, [filter, allListings, pendingListings, filterListings]);

    const handleApprove = async (listingID) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/approve-listing/${listingID}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            const data = await res.json()
            console.log(data)
            getPendingListings()
            if (res.ok) {
                toast.success('Listing Approved Successfully.')
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    const handleReject = async (listingID, mailData) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/reject-listing/${listingID}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            const data = await res.json()
            console.log('rejection data - ', data)
            if (res.ok) {
                sendEmail(mailData)
                // toast.success('Listing Rejected Successfully.')
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    const sendEmail = async (mailData) => {
        const htmlContent = `
       <div style="font-family: Arial, sans-serif; background-color: #f4f4f4; padding: 20px; color: #333;">
       <div style="max-width: 600px; margin: 0 auto; background-color: #fff; padding: 20px; border-radius: 8px; x-shadow: 0 10px rgba(0, 0, 0, 0.1);">
           <div style="background-color: #334155; padding: 10px; text-align: center; color: #fff; border-radius:8px 8px 0 0;">
               <h1>Property Rejection Notice</h1>
    </div>
             <div style="padding: 20px;">
               <h2>Hello ${mailData?.hostName},</h2>
               <p>We regret to inform you that your property <strong>${mailData?.title}</strong> , listed on ${mailData?.creationDate} , has been rejected due to the following reason:</p>
               <p style="font-style: italic; color: red;">${mailData?.reason}</p>
               <p>Please review your property details and ensure they comply with our company's terms and regulations. You may update and resubmit your listing for further review.</p>
               
               <div style="width : 100% ; display: flex; justify-content: center; align-items: center;">
               <a href="${process.env.REACT_APP_FRONTEND_URL}/${mailData?.hostID}/properties" style="display: inline-block; margin : 0 auto ; padding: 10px 20px; background-color: #334155; color: white; text-decoration: none; border-radius: 5px; margin-top: 20px;">View Your Property</a>
               </div>
             </div>
             <div style="text-align: center; padding: 10px; font-size: 12px; color: #334155;">
               <p>If you have any questions, feel free to contact us at support@tripittoday.com.</p>
             </div>
           </div>
         </div>
       `;
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/send-mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    email: mailData?.email,
                    subject: 'Property Rejection Notice',
                    role: "host",
                    content: htmlContent
                })
            })
            const data = await res.json()
            console.log("mail response - ", data)
            if (res.ok) {
                toast.info('Listing Rejected! Email sent to host successfully.')
                // setMailData({}); // refresh 
            }
        } catch (err) {
            console.log(err)
        }
    }

    const predefinedReasons = [
        {
            heading: "Incomplete Information",
            description: "The listing lacks essential details such as photos or descriptions."
        },
        {
            heading: "Violation of Terms",
            description: "The property does not comply with our terms and conditions."
        },
        {
            heading: "Suspicious Listing",
            description: "There are concerns about the authenticity of this property."
        },
        {
            heading: "Duplicate Listing",
            description: "The property is already listed on the platform."
        }
    ];

    const handleRadioChange = (event) => {
        const selectedHeading = event.target.value;
        setSelectedOption(selectedHeading);
        setReason(selectedHeading);
        setCustomReason("");
    };

    const handleCustomReasonChange = (event) => {
        setCustomReason(event.target.value);
        setReason(event.target.value);
        setSelectedOption("custom");
    };
    //console.log('hostData - ' , hostData)


    return (
        <>
            {user && model === 'admin'
                ? (
                    <>
                        <SideBar />
                        <div className='h-screen md:pl-[70px] lg:pl-[260px] w-full bg-slate-50 flex flex-col justify-start items-center  py-12'>
                            <div className='w-full font-extrabold text-slate-800 text-4xl text-center tracking-wide mb-8'>
                                Pending Listings
                            </div>
                            <div className='w-full flex items-center justify-end px-12'>
                                <div className={`px-5 py-1  ${filter === "All" ? "bg-slate-700 text-white" : "bg-slate-100 text-slate-700"} font-semibold rounded-l cursor-pointer`}
                                    onClick={() => setFilter("All")}>
                                    All
                                </div>
                                <div className={`px-5 py-1 ${filter === "Approved" ? "bg-slate-700 text-white" : "bg-white/80 text-slate-700"} font-semibold  cursor-pointer`}
                                    onClick={() => setFilter("Approved")}>
                                    Approved
                                </div>
                                <div className={`px-5 py-1 ${filter === "Rejected" ? "bg-slate-700 text-white" : "bg-white/80 text-slate-700"} font-semibold rounded-r cursor-pointer`}
                                    onClick={() => setFilter("Rejected")}>
                                    Rejected
                                </div>
                            </div>

                            <div className='flex flex-col  gap-1 items-center justify-start p-10'>
                                { filteredListings?.length === 0 
                                ?
                                <div className='w-full text-center font-semibold text-slate-700 text-2xl p-10'>No Listings to display.</div>
                                :
                                    filteredListings?.map((listing, index) => (
                                        <div key={index} className='w-full grid grid-cols-2 sm:grid-cols-6 gap-2 bg-white shadow-md px-2 py-6 rounded'>
                                            {/*------------------ LISTING PHOTOS IDHR----------- */}
                                            <div className='col-span-2 w-full flex flex-col items-center justify-start'>
                                                <div className='w-[250px] h-[135px] mb-[4px]'>
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${listing?.listingPhotoPaths[0]?.replace("public", "")}`} alt='listing' className='w-full h-full object-cover' />
                                                </div>
                                                {listing?.listingPhotoPaths?.length > 1 &&
                                                    <div className='gap-[5px] w-[250px] grid grid-cols-3 '>
                                                        <div className="h-16 w-[80px]"><img src={`${process.env.REACT_APP_BACKEND_URL}/${listing?.listingPhotoPaths[1]?.replace("public", "")}`} alt='listing' className='w-full h-full object-cover' /></div>
                                                        <div className="h-16 w-[80px]"><img src={`${process.env.REACT_APP_BACKEND_URL}/${listing?.listingPhotoPaths[2]?.replace("public", "")}`} alt='listing' className='w-full h-full object-cover' /></div>
                                                        <div className="h-16 w-[80px]"><img src={`${process.env.REACT_APP_BACKEND_URL}/${listing?.listingPhotoPaths[3]?.replace("public", "")}`} alt='listing' className='w-full h-full object-cover' /></div>
                                                    </div>}
                                                {listing?.listingPhotoPaths?.length > 4 &&
                                                    <Link to={`/properties/${listing?._id}`} className='w-full text-center  font-semibold text-slate-700 text-xs'>View {listing?.listingPhotoPaths?.length - 4} more images </Link>}

                                            </div>

                                            {/* ---------------PROPERTY NAME, CITY, COUNTRY, CATEGORY, TYPE, PRICE PER NIGHT ------------- */}
                                            <div className='col-span-2 flex flex-col w-full '>
                                                <div className='font-extrabold w-full text-2xl text-slate-700'>{listing?.title}</div>
                                                <div className='text-slate-700 font-bold w-full'>in {listing?.city}, {listing?.country}</div>
                                                <div className='text-gray-600 text-xs font-semibold w-full'> {listing?.type} | {listing?.guestCount} Guest(s), {listing?.bedroomCount}  Bedroom(s), {listing?.bedCount} Bed(s), {listing?.bathroomCount} Bathroom(s) </div>

                                                <div className='text-slate-700 font-semibold py-2 w-full'> <span>Category : </span>{listing?.category[0]}</div>

                                                <div className='flex flex-wrap gap-[2px] w-full'>
                                                    {listing?.facilities?.map((facility, index) => (
                                                        <div key={index} className='bg-black/40 text-white px-1.5 py-0.5 rounded font-semibold text-[11px] '> {facility} </div>
                                                    ))}
                                                </div>


                                                <div className='text-slate-800 font-bold text-lg pt-2 pr-4 text-end w-full'> {listing?.price} USD <span className='text-sm text-slate-500 font-semibold'>per night </span></div>
                                            </div>
                                            {/* --------------- CREATED AT,  HOST, VIEW DETAILS , APPROVE BUTTON--------------- */}
                                            <div className='col-span-2 flex flex-col  justify-between gap-3 '>
                                                <div>
                                                    <div className='font-semibold text-sm text-slate-700'>Created At :{format(new Date(listing?.createdAt), 'dd-MM-yyyy HH:mm')}</div>
                                                    <div className='font-semibold text-sm text-slate-700'>Host Details : </div>
                                                    <div className='flex w-full  rounded  border justify-between items-center gap-2 p-2'>
                                                        <div className='flex w-full flex-col items-start justify-center gap-2'>
                                                            <div className='font-semibold text-sm text-slate-700'>Name : {hostData[listing?.hostId]?.firstName + " " + hostData[listing?.hostId]?.lastName}</div>
                                                            <div className='font-semibold text-sm text-slate-700'>Email : {hostData[listing?.hostId]?.email}</div>
                                                        </div>
                                                        <div className='flex justify-center items-center gap-2 '>
                                                            <div className='w-12 h-12 rounded flex items-center justify-center'>
                                                                <img src={`${process.env.REACT_APP_BACKEND_URL}/${hostData[listing?.hostId]?.avatar?.replace("public", "")}`} alt='host' className='w-full h-full object-cover rounded' />
                                                            </div>

                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className='flex flex-col gap-3 items-center justify-center w-full'>
                                                        <div className='flex items-center cursor-pointer gap-2 px-3 py-1.5 font-semibold border border-slate-700 hover:border-white rounded bg-white text-slate-700 hover:bg-slate-700 hover:text-white'
                                                            onClick={() => navigate(`/properties/${listing?._id}`)}
                                                        >View more details <FaAngleRight /></div>
                                                    </div>
                                                    <div className='flex gap-1 items-center justify-center w-full'>
                                                        <div className='px-3 py-1.5 text-white w-32 font-semibold tracking-wide rounded bg-green-600 flex items-center justify-center gap-2 cursor-pointer'
                                                            onClick={() => handleApprove(listing?._id)}
                                                        >Approve <FiCheckCircle /> </div>
                                                        <div
                                                        
                                                            className='px-3 py-1.5 text-white w-32 font-semibold tracking-wide rounded bg-red-500 flex items-center justify-center gap-2 cursor-pointer'
                                                            onClick={() => {
                                                                setRsnData(
                                                                    {
                                                                        listingID:  listing?._id,
                                                                        title: `${listing?.title}`,
                                                                        city: `${listing?.city}`,
                                                                        country: `${listing?.country}`,
                                                                        creationDate: `${format(new Date(listing?.createdAt), 'dd-MM-yyyy, HH:mm')}`,
                                                                        hostName: `${hostData[listing?.hostId]?.firstName + " " + hostData[listing?.hostId]?.lastName}`,
                                                                        hostEmail: hostData[listing?.hostId]?.email,
                                                                        hostID : `${listing?.hostId}`

                                                                    });
                                                                setRsn(!rsn)
                                                            }
                                                            }
                                                        >
                                                            Reject  <IoMdCloseCircleOutline />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))
                                }
                                        </div>
                        </div>
                            {
                                rsn && <div className=' md:pl-[70px] lg:pl-[260px] fixed top-0 left-0 w-full h-screen bg-black/50 flex items-center justify-center '>
                                    <div className='w-[500px] bg-white flex flex-col items-start justify-center p-6 rounded shadow-lg'>
                                        <div className='font-bold text-2xl w-full border-b pb-2 mb-2 text-slate-800'>
                                            Rejection Reason
                                        </div>
                                        <div className='flex flex-col gap-1 text-sm p-2 mb-2 border border-red-300 bg-white rounded  w-full'>
                                            <div className='text-lg font-semibold text-slate-800'>Property  : {rsnData.title} <span className='text-sm'>in {rsnData.city}, {rsnData?.country}</span></div>
                                            <div className='w-full justify-between flex items-center gap-2'>
                                                <div className='font-semibold text-slate-800'>Host Name : {rsnData.hostName}</div>
                                                <div className='font-semibold text-slate-800'>Host Email : {rsnData.hostEmail}</div>
                                            </div>
                                        </div>
                                        {/* Render predefined reasons */}
                                        {predefinedReasons.map((reasonObj, index) => (
                                            <div key={index} className="mb-2">
                                                <label className="flex items-start">
                                                    <input
                                                        type="radio"
                                                        name="reason"
                                                        value={reasonObj.heading}
                                                        checked={selectedOption === reasonObj.heading}
                                                        onChange={handleRadioChange}
                                                        className="mr-2 mt-2 cursor-pointer"
                                                    />
                                                    <div className="flex flex-col cursor-pointer">
                                                        <span className="font-semibold">{reasonObj.heading} </span>
                                                        <span className='text-xs text-slate-800 font-semibold tracking-wide'>{reasonObj.description}</span>
                                                    </div>
                                                </label>
                                            </div>
                                        ))}

                                        {/* Custom reason option */}
                                        <div className="mb-2">
                                            <label className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name="reason"
                                                    value="custom"
                                                    checked={selectedOption === "custom"}
                                                    onChange={() => setSelectedOption("custom")}
                                                    className="mr-2 cursor-pointer"
                                                />
                                                <span className="font-semibold cursor-pointer">Custom Reason</span>
                                            </label>

                                        </div>
                                        {/* Textarea for custom reason, shown only when 'custom' is selected */}
                                        {selectedOption === "custom" && (
                                            <textarea
                                                value={customReason}
                                                onChange={handleCustomReasonChange}
                                                placeholder="Enter the reason here"
                                                className='w-full h-32 border outline-none rounded p-2 mt-2' />
                                        )}
                                        <div className='flex w-full justify-end items-center gap-3 mt-4'>
                                            <div className='px-5 py-1.5 bg-transparent border border-slate-700 text-slate-700 font-semibold rounded cursor-pointer'
                                                onClick={() => {
                                                    setRsnData({});
                                                    setRsn(!rsn)
                                                }}
                                            >Cancel</div>
                                            <div className='px-5 py-1.5 bg-slate-700 border border-slate-700 text-white font-semibold rounded cursor-pointer'
                                                onClick={() => {
                                                    const reqdData = {
                                                        listingID: rsnData.listingID,
                                                        title: rsnData.title,
                                                        creationDate: rsnData.creationDate,
                                                        hostName: rsnData.hostName,
                                                        email: rsnData.hostEmail,
                                                        reason: reason,
                                                        hostID : rsnData.hostID
                                                    }
                                                    // setMailData(reqdData)
                                                    handleReject(rsnData?.listingID, reqdData)
                                                    setRsn(!rsn)
                                                }}
                                            >Send mail</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                        )
                        :
                        (
                        <div>Not Authorised</div>
                        )
            }
                    </>)
}

            export default GPENDING