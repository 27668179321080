import React from 'react';

const SubscriptionPlans = () => {
  return (
    <div className="min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/login.jpg')" }}>
      <div className="flex flex-col items-center justify-center min-h-screen backdrop-filter backdrop-brightness-75 px-4 sm:px-6 lg:px-8">
        
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-white mb-8 sm:mb-10 md:mb-12 drop-shadow-lg shadow-black text-center">
          Choose Your Plan
        </h1>
        
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-8 sm:gap-10 md:gap-12 px-4 sm:px-8 md:px-12">
          {/* Plan 1 */}
          <div className="relative max-w-full sm:max-w-sm p-6 sm:p-8 bg-black bg-opacity-50 backdrop-filter backdrop-blur-xl border border-gray-300 rounded-lg shadow-2xl hover:shadow-blue-500/50 transition-transform transform">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white drop-shadow-md">One Year-Round Specials</h2>
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white drop-shadow-md">
  <span className="line-through mr-4 decoration-red-500">599€</span>
  <span>499€/-</span>
</h2>
            <p className="mb-4 text-sm sm:text-base text-gray-300 leading-relaxed">
              Elevate your business profile with our exclusive One-Year Listing, guaranteeing extended visibility and promotional opportunities throughout the year.
            </p>
            <ul className="list-disc pl-4 sm:pl-5 mb-6 text-xs sm:text-sm text-gray-200">
              <li> Digital Dominance Package</li>
              <li> Strategic Visibility Solutions</li>
              <li> Partner-Powered Prominence</li>
              <li> Inquiry Magnet Pro</li>
              <li> Visual Brilliance Bundle</li>
              <li> Booking Boost Pro</li>
              <li> Engagement Accelerator</li>
              <li> TraverseConnect Plus</li>
              <li> Conversion Catalyst Suite</li>
              <li> Seamless Booking Advantage</li>
              <li> Epic Exposure Ensemble</li>
              <li> Inquiry Amplifier Elite</li>
              <li> Direct Connect Dynamics</li>
              <li> Booking Velocity Blueprint</li>
              <li> Conversion Craftsmanship Suite</li>
            </ul>
            <div className="flex justify-center">
              <button className="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-2 px-4 sm:px-6 rounded-lg shadow-lg hover:from-blue-700 hover:to-blue-900 transition-all">
                Buy Plan
              </button>
            </div>
          </div>

          {/* Plan 2 */}
          <div className="relative max-w-full sm:max-w-sm p-6 sm:p-8 bg-black bg-opacity-50 backdrop-filter backdrop-blur-xl border border-gray-300 rounded-lg shadow-2xl hover:shadow-blue-500/50 transition-transform transform ">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white drop-shadow-md">Two-Year Infinite Impact</h2>
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white drop-shadow-md">
  <span className="line-through mr-4 decoration-red-500">899€</span>
  <span>799€/-</span>
</h2>
            <p className="mb-4 text-sm sm:text-base text-gray-300 leading-relaxed">
              Unlock the potential of sustained growth and visibility with our 2-Year Listing – the ideal choice for those seeking long-term promotional excellence.
            </p>
            <ul className="list-disc pl-4 sm:pl-5 mb-6 text-xs sm:text-sm text-gray-200">
              <li> Unlimited content access</li>
              <li> Priority customer support</li>
              <li> 2-year subscription</li>
            </ul>
            <div className="flex justify-center">
              <button className="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-2 px-4 sm:px-6 rounded-lg shadow-lg hover:from-blue-700 hover:to-blue-900 transition-all">
                Buy Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
