import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"
import Header2 from "../components/Header2"
import GFooter from "../components/GFOOTER" 
import { useDispatch, useSelector } from "react-redux"
import { setWishList, setPropertyList, setLogin } from '../redux/state';
import { isTokenExpired } from "../utility/CheckToken"
import { toast } from "react-toastify"
import { MdPhotoCamera } from "react-icons/md";

export const RegisterPage = () => {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    avatar: null,
  });

  const currentPath = useLocation().pathname; // Get the current path or URL | Helpful for knowing if its USER or HOST

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [name]: name === "avatar" ? files[0] : value,
    });
  };
  const [passwordMatch, setPasswordMatch] = useState(true);

  useEffect(() => {
    setPasswordMatch(
      formData.password === formData.confirmPassword || formData.confirmPassword === ""
    );
  }, [formData.password, formData.confirmPassword]);


  const navigate = useNavigate()
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const model = useSelector((state) => state.modelType);
  //console.log("Token", token, "Model", model);

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      //console.log("Token expired");
      dispatch(setLogin({
        user: null,
        token: null,
        modelType: null
      }));
      console.log('Session expired. Please log in again.');
      model === "host" ? navigate('/host_login') : navigate('/login');
      return;
    }
  }, [token, model, dispatch, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    //console.log("Form Data → ", formData)

    try {
      const register_form = new FormData()

      for (var key in formData) {
        register_form.append(key, formData[key])
      }
      //console.log("Register form → " , register_form)
      //console.log("register_form", register_form, "formData", formData);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${currentPath === "/host_register" ? "hosts" : "users"}/register`, {
        method: "POST",
        body: register_form,
      })

      if (response.ok) {

        toast.success(`Successfully registered!`);

        loginFunction({
          email: formData?.email,
          password: formData?.password,
          model: currentPath === "/host_register" ? "host" : "user"
        })
      }
    } catch (err) {
      console.log("Registration failed", err.message)
      toast.error(`Registration Failed. Please try again later.`);
    }
  }

  const loginFunction = async ({ email, password, model }) => {
    //console.log("Login Function", email, password, model)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${model}s/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      })
      const loggedIn = await response.json()
      if (loggedIn) {
        dispatch(
          setLogin({
            user: loggedIn.data[model === "host" ? "hostData" : "user"],
            token: loggedIn.data.accessToken,
            modelType: model
          })
        )

        //User Wishlist fetch itthe
        if (model === "user") {
          const wishlistResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${model}s/wishlists`, {
            headers: {
              Authorization: `Bearer ${loggedIn.data.accessToken}`
            }
          });
          const wishlistData = await wishlistResponse.json();
          //   console.log("Wishlist Data", wishlistData.data);
          dispatch(setWishList(wishlistData));

        }
        //Host Properties/Listings fetch itthe
        if (model === "host") {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${model}s/get-listings`, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${loggedIn.data.accessToken}`, // Include token in the Authorization header
              "Content-Type": "application/json"
            }
          })
          const data = await response.json()
          // console.log("propertyList: ",data);
          dispatch(setPropertyList(data.data))
        }
        navigate('/dashboard')
        //toast.success(` Successfully logged in! Welcome, ${loggedIn.data[model === "host" ? "hostData" : "user"].firstName}. `);
      }

    } catch (err) {
      console.log("Login failed.", err.message)
    }
  }

  // SIDE STUFF IMAGES ITTHE
   
  const images = ['/assets/G5.jpg', '/assets/G6.jpg', '/assets/G7.jpg', '/assets/G8.jpg'];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); 

    return () => clearInterval(intervalId); 
  }, [images.length]);

  return (
    <>
      <Header2 />

      <div className="register_content  sm:h-screen w-full bg-slate-100 flex  justify-center items-center">
        <div className='group flex flex-col-reverse sm:flex-row justify-center items-center h-full sm:h-[550px] mt-14 sm:mt-6  shadow-xl rounded-md overflow-hidden'>
          {/* -----------------REGISTER PAGE---------------- */}
          <form onSubmit={handleSubmit} className=" w-[320px] sm:w-[500px] flex flex-col items-center justify-start py-8 px-8 shadow-lg  gap-5 bg-white"    >
            <h1 className='text-center text-3xl !font-extrabold mb-1 '>
              {`${currentPath === '/host_register' ? 'Host' : 'User'} Registration`}
            </h1>
            <div className=" -mt-2 relative flex flex-col items-center justify-center">
              {formData?.avatar
                ?
                <img src={URL.createObjectURL(formData.avatar)} alt="profile pic" className="h-24 w-24  rounded-full" />
                :
                <img src="/assets/avatar.jpg" alt="Profile Pic" className="h-24 w-24  rounded-full" />
              }
              
              <label htmlFor="image" className=" cursor-pointer"> 
                <div className="text-center p-1 text-xs font-semibold  text-slate-500 flex items-center gap-1"> <MdPhotoCamera className=" text-sm text-slate-500"/> Upload Your Image </div> </label>
            </div>

            <div className=" w-full sm:space-x-2 gap-5 flex flex-col sm:flex-row sm:justify-between">
              <input
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className='py-2 px-4 w-full text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
                required
              />
              <input
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className='py-2 px-4 w-full text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
                required
              />
            </div>
            <input
              placeholder="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className='py-2 px-4 w-full text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
              required
            />
            <input
              placeholder="Phone Number"
              name="phoneNumber"
              type="text"
              value={formData.phoneNumber}
              onChange={handleChange}
              className='py-2 px-4 w-full text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
              required
            />
            <div className="relative w-full sm:space-x-2 gap-5 flex flex-col sm:flex-row sm:justify-between">
              <input
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                type="password"
                className='py-2 px-4 w-full text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
                required
              />
              <input
                placeholder="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                type="password"
                className='py-2 px-4 w-full text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
                required
              />
            {!passwordMatch && (
              <p className="absolute right-1 -bottom-4 text-red-500 -mt-5 w-full block text-end text-xs font-light" >Passwords are not matched!</p>
            )}
            </div>

            <input
              id="image"
              type="file"
              name="avatar"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleChange}
              className='py-2 px-4 border-2 text-sm bg-gray-100 rounded-sm focus:border-b focus:outline-none '
            />

            <button type="submit" disabled={!passwordMatch} className='w-full text-lg bg-slate-700 hover:bg-slate-800 hover:shadow-md px-6 py-1 text-white rounded-sm '>Sign Up</button>
            <Link to={`/${currentPath === "/host_register" ? "host_" : ""}login`} className="mt-3 tracking-wide text-sm">Already have an account? <span className="text-slate-800 font-semibold">Log In </span></Link>
          </form>
          {/* ------------------SIDE STUFF------------------ */}
          <div className="h-40 sm:h-full w-[320px] sm:w-[350px] relative overflow-hidden">
          {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slide-${index}`}
                className={` object-cover h-full w-full absolute inset-0 transition-opacity duration-[2000ms] ease-in-out ${
                  index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                }`}
              />
            ))}

            <div className='absolute translate-y-full duration-1000 group-hover:translate-y-0 inset-0 bg-black/40 text-white font-bold text-2xl sm:text-3xl p-4 flex items-center text-center'>
            {currentPath === '/register'  ? "Sign up today and start exploring unforgettable destinations." : "Host with confidence. Register now and get started in minutes."}</div>

            <div className='absolute bottom-3 right-3 drop-shadow-2xl '>
              <img src="/logo4.png" alt="" className='h-7 w-7  object-cover' />
            </div>
          </div>
        </div>

      </div>
      <GFooter />
    </>
  );
};

