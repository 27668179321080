import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header2';
import Footer from '../components/GFOOTER';

const PaymentFail = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract query parameters from URL
    const queryParams = new URLSearchParams(location.search);
    const paymentId = queryParams.get('paymentId');
    const payerId = queryParams.get('PayerID');
    console.log('Payment ID:', paymentId, '\nPayer ID:', payerId);

    // Optionally, you can handle further actions if needed
    if (paymentId || payerId) {
      // Optional: Handle or log cancellation if needed
    } else {
      console.error('Payment ID or Payer ID is missing.');
    }
  }, [location.search]);

  return (
    <>
      <Header />
      <div className="py-16 min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-4 sm:p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-semibold text-center mb-4">Payment Cancelled</h2>
          <div className="flex justify-center mb-4">
            <img src="/assets/cancel.png" alt="payment-cancelled" />
          </div>
          <p className="text-center text-lg font-semibold mb-2">The payment was cancelled. Please try again or contact support if you need assistance.</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentFail;
