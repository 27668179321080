import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Header from '../components/Header2';
import Footer from '../components/GFOOTER';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

const GPAY = () => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // faaltu m /booking-payment  pr aane ka nahi
    if (!location.state || !location.state.booking) {
      toast.warn('Choose the trip for which you want to pay.');
      navigate(`/${user?._id}/trips`, { replace: true })

    }
  }, [location, user?._id, navigate]);

  const { booking } = location.state || {};

  const amount = booking?.total  // total amount for trip GTM

  const createOrder = async (event) => {
    event.preventDefault();  // Prevent form from refreshing the page
    const reservation_ID = booking?.id;
    // Send the payment amount to the backend
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/create-order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        amount,
        // returnUrl: `http://localhost:3000/payment/success?reservationId=${reservation_ID}`,
        // cancelUrl: `http://localhost:3000/payment/fail`
         returnUrl: `${process.env.REACT_APP_FRONTEND_URL}/payment/success?reservationId=${reservation_ID}`,
         cancelUrl: `${process.env.REACT_APP_FRONTEND_URL}/payment/fail`
      })
    });

    if (response.ok) {
      // const { orderId, approvalUrl } = await response.json();
      const {  approvalUrl } = await response.json();
      //console.log('OrderId:', orderId, '\nApproval URL:', approvalUrl);
      // Redirect the user to the approval URL
      window.location = approvalUrl;

    } else {
      console.error('Order creation failed');
    }
    setTimeout(() => {
      setLoading(false);
    }, 5000);  };


  if (!booking) return null; // booking nahi → kuch nahi dikhega

  return (
    <>
      <Header />
      <div className="py-16 min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-4 sm:p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-semibold text-center mb-4">Payment</h2>
          <div className="flex justify-center mb-4">
            <img src="/assets/payment.png" alt="payment" />
          </div>

          <div className="w-full flex justify-between items-center py-3 ">
            <div className='flex flex-col items-start justify-center'>
              <div className="text-center text-sm font-semibold text-slate-700">Property Name</div>
              <div className="text-center text-xl mb-6 font-extrabold text-blue-800">
                <Link to={`/properties/${booking?.id}`} className="hover:underline">
                  {booking?.name}
                  <span className="text-xs text-slate-900">
                    ({format(new Date(booking?.start), "dd MMM yyyy")} - {format(new Date(booking?.end), "dd MMM yyyy")})
                  </span>
                </Link>
              </div>
            </div>
            <div className='flex flex-col items-end justify-center'>
              <div className="text-center text-sm font-semibold text-slate-700">Payable amount</div>
              <div className="text-center text-2xl mb-6 font-extrabold">
                {booking?.total}
                <span className="text-base text-slate-900"> {booking?.currency}</span>
              </div>
            </div>


          </div>

          <form onSubmit={createOrder}>
            <div className='flex flex-col gap-1.5 items-between justify-between mb-4'>
              <div className="flex items-center justify-start font-bold w-full border-b text-lg "> Trip Details</div>
              <div className="flex justify-between items-center px-2 " >
                <div className="text-sm font-semibold text-slate-700">Trip ID</div>
                <div className="text-sm font-extrabold  text-slate-700">{booking?.id}</div>
              </div>
              <div className="flex justify-between items-center px-2 " >
                <div className="text-sm font-semibold text-slate-700">Property Name</div>
                <div className="text-sm font-extrabold  text-slate-700">{booking?.name}</div>
              </div>
              <div className="flex justify-between items-center px-2" >
                <div className="text-sm font-semibold text-slate-700">Price per Night</div>
                <div className="text-sm font-extrabold text-slate-700">{booking?.price} {booking?.currency}</div>
              </div>
              <div className="flex justify-between items-center px-2" >
                <div className="text-sm font-semibold text-slate-700">Check In</div>
                <div className="text-sm font-extrabold text-slate-700">{format(new Date(booking?.start), "MMMM dd, yyyy")}</div>
              </div>
              <div className="flex justify-between items-center px-2" >
                <div className="text-sm font-semibold text-slate-700">Check Out</div>
                <div className="text-sm font-extrabold text-slate-700">{format(new Date(booking?.end), "MMMM dd, yyyy")}</div>
              </div>
              <div className="flex justify-between items-center  border-t px-1.5" >
                <div className="text-base font-extrabold">Total Amount</div>
                <div className="text-base font-extrabold">{booking?.total} {booking?.currency}</div>
              </div>
            </div>

            <div className="flex w-full justify-between items-center gap-2">
              <button
                onClick={() => { toast.info("Payment cancelled!"); navigate(`/${user?._id}/trips`, { replace: true }) }}
                className="w-full py-2 !bg-transparent !bg-none border border-slate-800  text-slate-800 font-semibold rounded shadow-md focus:outline-none "
              >
                Cancel
              </button>
              <button
                type="submit"
                className="relative w-full py-2 bg-slate-800 border border-slate-800 hover:bg-slate-900 text-white font-semibold rounded shadow-md focus:outline-none overflow-hidden "
                onClick={() => setLoading(true)}
              >
                Proceed to Pay
                {loading &&
                  <div className={`absolute w-full inset-0 top-0 right-0  bg-white/50  flex items-center justify-center `}>
                    <div className="w-5 h-5 border-4 border-white border-r-slate-800 rounded-full animate-spin"></div>
                  </div>
                }

              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GPAY;
