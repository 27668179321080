import { useState } from 'react';
import Header from '../components/Header2';
import Footer from '../components/GFOOTER';

const PaymentForm = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    streetNumber: "",
    unitNumber: "",
    streetName: "",
    city: "",
    state: "",
    zipCode: "",
    bankName: "",
    routingNo: "",
    accountNumber: "",
    checkNo: "",
    amount: "",
    notes: ""
  });

  const handleChange = (e) => {
    setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/pay-with-check`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(paymentDetails)
      });
      const data = await response.json();
      if (data.success) {
        alert("Payment successful!");
      } else {
        alert("Payment failed: " + data.message);
      }
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="py-16 min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-4 sm:p-6 rounded shadow-md w-full max-w-md">
          <h2 className="text-2xl font-semibold text-center mb-4">ECheck Payment Form</h2>
  
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email Address</label>
              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Street Number</label>
              <input
                type="text"
                name="streetNumber"
                placeholder="Street Number"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Unit Number</label>
              <input
                type="text"
                name="unitNumber"
                placeholder="Unit Number"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Street Name</label>
              <input
                type="text"
                name="streetName"
                placeholder="Street Name"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">City</label>
              <input
                type="text"
                name="city"
                placeholder="City"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">State</label>
              <input
                type="text"
                name="state"
                placeholder="State"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Zip Code</label>
              <input
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Bank Name</label>
              <input
                type="text"
                name="bankName"
                placeholder="Bank Name"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Routing Number</label>
              <input
                type="text"
                name="routingNo"
                placeholder="Routing Number"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Account Number</label>
              <input
                type="text"
                name="accountNumber"
                placeholder="Account Number"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Check Number</label>
              <input
                type="text"
                name="checkNo"
                placeholder="Check Number"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="text"
                name="amount"
                placeholder="Amount"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              />
            </div>
  
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                name="notes"
                placeholder="Notes"
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-slate-800 focus:border-slate-800 sm:text-sm"
              ></textarea>
            </div>
  
            <button
              type="submit"
              className="w-full py-3 bg-slate-800 hover:bg-slate-900 text-white font-semibold rounded shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-800 transition-colors"
            >
              Pay with Check
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
  
};

export default PaymentForm;
