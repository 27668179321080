import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bg_img from "../assets/agent_avatar.png";
import CRMError from "./CRMError";
import { useSelector } from "react-redux";
import CRMHeader from "./CRMHeader";
import CRMSidebar from "./CRMSidebar";
import LeadCard from "./LeadCard";
import Widgets from "./Widgets";
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const backendData = {
  firstName: "Gautam",
  lastName: "Arora",
  email: "gtmdhoni@gmail.com",
  contact: "1234567890",
  avatar: `${bg_img}`
};

const CRM = () => {
  //const [STD, setSTD] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const modelType = useSelector((state) => state.modelType);

  const [exist, setExist] = useState(false);
  const [isFreshLead, setIsFreshLead] = useState(false);
  const [hostData, setHostData] = useState({});
  const [msg, setMsg] = useState("");


  // console.log("phoneNumber: ", phoneNumber);

  const handleCheckLead = async () => {
    // Combine STD code and phone number
    const fullPhoneNumber =  phoneNumber;
    // const fullPhoneNumber = STD ? `${STD}${phoneNumber}` : phoneNumber;
    console.log("(handleCheckLead) Full Phone Number: ", fullPhoneNumber);
    if (!fullPhoneNumber || fullPhoneNumber.length < 10) {
      toast.warn("Please enter a valid phone number.");
      return;
    }

    setExist(false);
    setHostData({});
    setIsFreshLead(false);
    setMsg("Checking Lead...");

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/agents/check-host`, {
        method: 'POST',
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ phoneNumber: fullPhoneNumber }),
      });

      const data = await response.json();
      if (data.message === "Host already exists") {
        setExist(true);
        setMsg("Host already exists");
        setHostData(data.data);
        toast.info("Host already exists.");
        //smoothScrollToRef(hostDataRef);
      } else {
        setExist(false);
        setIsFreshLead(true);
        setMsg("This is a FRESH Lead!");
        toast.info("This is a FRESH Lead!");
        //smoothScrollToRef(actionDivRef);
      }

    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred while checking the lead.");
    }
  };

  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue && selectedValue[0] === '/') {
      navigate(selectedValue, { state: { phoneNumber } });
    }
  };

  const data = user ? user.agent : backendData;

  return (
    <>
      {user && modelType === "agent"
        ? <>
          <CRMHeader />
          <CRMSidebar />
          <div className="ml-16 pb-16 h-full bg-gray-100 ">
            <div className="p-4">
              <div className="w-full flex gap-x-4">
                <div className="bg-white w-3/4 shadow px-4 py-2 rounded-lg">
                  <div className="text-xl font-semibold w-full pb-2 mb-3 border-b">
                    Welcome to <Link to="/" className="font-bold">TripItToday</Link>, <span className="text-rose-500 text-2xl font-medium">{` ${data?.firstName} ${data?.lastName}`}</span>
                  </div>

                  <div className="flex justify-between items-center gap-x-8">
                    <div className="bg-white border-r w-full flex justify-center items-center">
                      <span className="w-40 h-40 rounded-full shadow bg-white flex justify-center items-center">
                        <img src={data?.avatar || backendData.avatar} alt="Profile Pic" className="object-cover w-40 h-40 rounded-full" />
                      </span>
                    </div>

                    <div className="relative w-full rounded-md bg-white flex flex-col gap-2 py-8 items-center">
                      <span className="font-semibold tracking-wide w-full text-center text-xl py-2">Agent Details</span>
                      <div className="w-full py-2 px-14 flex gap-2">
                        <div className="w-[100px] flex justify-between font-medium">
                          <span>Name</span>
                          <span>:</span>
                        </div>
                        <div>{`${data.firstName} ${data.lastName}`}</div>
                      </div>
                      <div className="w-full py-2 px-14 flex gap-2">
                        <div className="w-[100px] flex justify-between font-medium">
                          <span>Contact</span>
                          <span>:</span>
                        </div>
                        <div>{data.phoneNumber}</div>
                      </div>
                      <div className="w-full py-2 px-14 flex gap-2">
                        <div className="w-[100px] flex justify-between font-medium">
                          <span>Email</span>
                          <span>:</span>
                        </div>
                        <div>{data.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <Widgets />
              </div>

              <div className="bg-white my-5 p-4 shadow rounded-md flex flex-col justify-center items-center">
                <p className="text-center py-4 text-sm text-gray-700">Check the status of the contact number you would like to call.</p>
                <div className=" w-full flex items-center  justify-center font-semibold text-sm gap-4 ">
                  Contact no.
                  {/* <input
                    type="text"
                    className="w-12 p-2 outline-none bg-slate-100 border-slate-700/50 rounded font-medium"
                    placeholder="STD"
                    value={STD}
                    onChange={(e) => setSTD(e.target.value.replace(/\D/g, ''))}
                  /> */}
                  <div className="">
                  <PhoneInput
                    country={'us'}
                    value={phoneNumber}
                    onChange={(value) => {
                      setExist(false);
                      setIsFreshLead(false);
                      setMsg("");
                      setPhoneNumber(value);
                    }}
                    inputClass="p-2 outline-none shadow-sm bg-slate-100 border-slate-700/50 rounded font-medium"
                    placeholder="Mobile"
                  />
                  </div>
                  <button
                    className="py-1 my-auto rounded px-3 flex justify-center items-center bg-slate-700 text-white hover:bg-slate-800"
                    onClick={handleCheckLead}
                  >
                    Check Lead
                  </button>
                </div>

                <p className={`text-sm text-center font-semibold mt-2 ${exist ? "text-blue-500" : "text-green-500"}`}>{msg}</p>

                {exist && hostData && (
                  <div className="mt-2 flex flex-col gap-1.5 w-full">
                    <LeadCard lead={hostData} />
                  </div>
                )}

                {isFreshLead && (
                  <div  className="flex justify-center mb-5 p-4 gap-2 w-full bg-white rounded-sm">
                    <div className="shadow-md p-1">
                      <select name="Choose Action" className="outline-none px-3 py-2" onChange={handleSelectChange}>
                        <option value="">Choose Action</option>
                        <option value="/CRM/create-offer">Send Offer</option>
                        <option value="/CRM/fresh/callback">Callback</option>
                        <option value="/CRM/fresh/paid">Paid</option>
                        <option value="/CRM/fresh/lang_barrier">Language Barrier</option>
                        <option value="/CRM/fresh/no_interest">Not Interested</option>
                        <option value="/CRM/fresh/DND">DND</option>
                        <option value="/CRM/fresh/not_connected">Not Connected</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
        : <CRMError model={modelType} />
      }
    </>
  );
};

export default CRM;
