import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SideBar from '../components/sidebar/Sidebar'
import { toast } from 'react-toastify'
const GCREATEPLAN = () => {
    const user = useSelector(state => state.user)
    const model = useSelector(state => state.modelType)
    const token = useSelector(state => state.token)
    const [currency, setCurrency] = useState('USD');

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    };


    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault();

        const planId = e.target.planId.value
        const name = e.target.name.value
        const actualPrice = e.target.actualPrice.value
        const discount = e.target.discount.value
        const period = e.target.period.value

        console.log(planId, name, actualPrice, discount, period, currency)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/create-plan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ planId, name, actualPrice, discount, period, currency: currency })
            })

            if (response.ok) {
                const data = await response.json()
                console.log(data);
                toast.success("Plan created successfully")
                navigate('/admin/dashboard')
            }

        } catch (err) {
            console.error(err)
            toast.error("Failed to create plan")
        }
    }
    return (
        <>
            {user && model === 'admin'
                ? (
                    <>
                        <SideBar />
                        <div className='h-screen md:pl-[70px] lg:pl-[260px] w-full bg-slate-50 flex flex-col justify-center items-center  py-12'>
                            <div className='w-full font-extrabold text-slate-800 text-4xl text-center tracking-wide mb-8'>
                                Create Plan
                            </div>

                            {/* -----PLAN FORM IDHAR--------planId , name, actualPrice, discount, period-- */}
                            <form onSubmit={handleSubmit} className="w-full px-24 " >
                                <div className='w-full flex flex-col space-y-3 bg-white rounded-md shadow-md py-6 px-10'>
                                    <div className='w-full flex justify-between items-center gap-2'>
                                        <label htmlFor='planId' className='text-slate-800 font-semibold'>Plan ID</label>
                                        <input required type='text' id='planId' name='planId' className='w-1/2 p-2 border border-slate-300 rounded-md' />
                                    </div>
                                    <div className='w-full flex justify-between items-center gap-2'>
                                        <label htmlFor='name' className='text-slate-800 font-semibold'>Plan Name</label>
                                        <input required type='text' id='name' name='name' className='w-1/2 p-2 border border-slate-300 rounded-md' />
                                    </div>
                                    <div className='w-full flex justify-between items-center gap-2'>
                                        <label htmlFor='currency' className='text-slate-800 font-semibold'>
                                            Currency
                                        </label>
                                        {/* ["USD", "EUR", "GBP", "JPY", "AUD", "CAD", "CHF", "CNY", "INR", "NZD"], */}
                                        <select
                                            id='currency'
                                            name='currency'
                                            className='w-1/2 p-2 outline-none border border-slate-300 rounded-md'
                                            value={currency}
                                            onChange={handleCurrencyChange}
                                        >
                                            <option value='USD'>USD</option>
                                            <option value='INR'>INR</option>
                                            <option value='EUR'>EUR</option>
                                            <option value='GBP'>GBP</option>
                                            <option value='JPY'>JPY</option>
                                            <option value='AUD'>AUD</option>
                                            <option value='CAD'>CAD</option>
                                            <option value='CHF'>CHF</option>
                                            <option value='CNY'>CNY</option>
                                            <option value='NZD'>NZD</option>

                                        </select>
                                    </div>

                                    <div className='w-full flex justify-between items-center gap-2'>
                                        <label htmlFor='actualPrice' className='text-slate-800 font-semibold'>Actual Price</label>
                                        <input required type='number' id='actualPrice' name='actualPrice' className='w-1/2 p-2 border border-slate-300 rounded-md' />
                                    </div>
                                    <div className='w-full flex justify-between items-center gap-2'>
                                        <label htmlFor='discount' className='text-slate-800 font-semibold'>Discount</label>
                                        <input required type='number' id='discount' name='discount' className='w-1/2 p-2 border border-slate-300 rounded-md' />
                                    </div>

                                    <div className='w-full flex justify-between items-center gap-2'>
                                        <label htmlFor='period' className='text-slate-800 font-semibold'>Period</label>
                                        <input required type='text' id='period' name='period' className='w-1/2 p-2 border border-slate-300 rounded-md' />
                                    </div>
                                    <div className='w-full flex justify-center items-center gap-2 my-4'>
                                        <button type="submit" className=' bg-slate-700 text-white font-semibold tracking-wider flex justify-between items-center py-2 px-6 mt-4' >
                                            Create
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </>
                )
                :
                (
                    <div>Not Authorised</div>
                )
            }
        </>
    )
}

export default GCREATEPLAN