import React from 'react';
import Header from '../components/Header2';
import Footer from "../components/GFOOTER";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SubscriptionDetails = () => {
  const user = useSelector((state) => state.user);
  const subscriptions = useSelector((state) => state?.user?.subscriptions);
  const model = useSelector((state) => state.modelType);
  const navigate = useNavigate();

  // If no subscription data is available, show a message
  if (!subscriptions || subscriptions.length === 0) {
    return <p className="text-center text-gray-500">No subscriptions found.</p>;
  }

  return (
    <>
      {user && model === "host" && (
        <>
          <Header />
          <div className="flex flex-col items-center mt-10">
            {subscriptions.map((subscription) => (
              <div key={subscription._id} className="w-full max-w-md mb-4">
                <div className="p-6 bg-white shadow-md rounded-lg border border-gray-200">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Subscription Plan: {subscription.name}
                  </h2>
                  <div className="mb-2">
                    <span className="font-bold">Plan ID:</span> {subscription.planId}
                  </div>
                  <div className="mb-2">
                    <span className="font-bold">Host ID:</span> {subscription.hostId}
                  </div>
                  <div className="mb-2">
                    <span className="font-bold">Actual Price:</span> ${subscription.actualPrice} {subscription.currency}
                  </div>
                  <div className="mb-2">
                    <span className="font-bold">Discount:</span> {subscription.discount}%
                  </div>
                  <div className="mb-2">
                    <span className="font-bold">Discounted Price:</span> ${subscription.discountedPrice} {subscription.currency}
                  </div>
                  <div className="mb-2">
                    <span className="font-bold">Created At:</span> {new Date(subscription.createdAt).toLocaleString()}
                  </div>
                  <div className="mb-2">
                    <span className="font-bold">Updated At:</span> {new Date(subscription.updatedAt).toLocaleString()}
                  </div>
                </div>
                <button
                  onClick={() => navigate('/create-listing', { state: { subscriptionId: subscription._id } })}
                  className="mt-6 px-6 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition-colors"
                >
                  Start Creating Your Listing
                </button>
              </div>
            ))}
          </div>
          <Footer />
        </>
      )}
    </>
  );
  
};

export default SubscriptionDetails;
