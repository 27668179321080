import React from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header2'
import Footer from '../components/GFOOTER'

const GPLANS = () => {
    const navigate = useNavigate();
    const host_plans = [
        {
            id: "plan01",
            name: "Bronze",
            currency: "USD",
            price: 25,
            discount: 0,
            period: "30 Days"
        },
        {
            id: "plan02",
            name: "Silver",
            currency: "USD",
            price: 50,
            discount: 10,
            period: "6 Months"
        },
        {
            id: "plan03",
            name: "Gold",
            currency: "USD",
            price: 100,
            discount: 0,
            period: "1 Year"
        },
    ]

    const handleClick = (id)=>
    {
        const chosenPlan = host_plans.find(plan => plan.id === id);
        navigate( '/payment', { state: { plan: chosenPlan } });

    }
    return (
        <>
            <Header />
            <div className="flex flex-col h-full sm:h-screen bg-slate-50  items-center justify-start">
                <div className="text-4xl font-bold text-slate-800 py-20">Payment Plans</div>

                <div className="flex flex-col sm:flex-row gap-8">
                    {host_plans.map((plan, index) => (
                        <div key={index} className="relative flex  flex-col items-center justify-between bg-white  p-4 shadow rounded-md h-72 w-64 hover:scale-105 hover:shadow-2xl duration-500 overflow-hidden">
                            <div className="w-full text-lg font-bold border-b">
                                <div className="text-2xl font-bold text-slate-700">{plan.name}</div>
                            </div>
                            <div className="w-full flex flex-col items-center justify-center">
                                <div className="text-4xl font-bold text-black flex items-end justify-center gap-2">
                                    { plan.discount > 0 &&  <span>{plan.price - (plan.price * plan.discount / 100) } </span> } 
                                    <span className={`${plan.discount>0 && "text-gray-500 line-through text-3xl"}`}>{plan.price} </span>
                                    <span className='text-sm'> {plan.currency}</span>
                                </div>
                                <div className="text-sm font-bold text-slate-700">{plan.period}</div>
                            </div>
                            <div className="text-lg w-full tracking-wider font-medium ">
                                <button className="bg-slate-700 w-full text-white px-4 py-2 rounded mt-4" onClick={() => handleClick(plan.id)}>Choose Plan</button>
                            </div>

                            {
                                plan.discount > 0 && (
                                    <div className="absolute top-4 -right-8 bg-red-500 text-sm text-white px-8 rotate-45  font-bold">{plan.discount}% Off</div>
                                )
                            }
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default GPLANS