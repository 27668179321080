import React, { useState, useEffect, useRef } from "react";
import cities from "../data/cities.json";


const CustomDropdown = ({ label, down, options, onSelect }) => {
  const [selected, setSelected] = useState(options[0]); // Initially selected option
  const [open, setOpen] = useState(false); // Dropdown open/close state
  const [searchTerm, setSearchTerm] = useState(""); // Search term for the input field
  const [filteredOptions, setFilteredOptions] = useState(options); // Filtered options based on search term
  const dropdownRef = useRef(null);

  // Debounce function to delay search input handling
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  // Handle selection of a city
  const handleSelect = (option) => {
    setSelected(option);
    setOpen(false);
    onSelect(option);
  };

  // Search function with debouncing
  const handleSearch = debounce((term) => {
    if (term === "") {
      // If no search term, show the first 30 options
      setFilteredOptions(options.slice(0, 30));
    } else {
      // Filter cities based on the search term
      const filtered = cities.filter((city) => {
        return city.city.toLowerCase().includes(term.toLowerCase())}
      ).slice(0, 50); // Limit the results to top 50 matches
      // console.log("filtered: ", filtered);

      setFilteredOptions(filtered.map((city) => city.city));
    }
  }, 300); // Delay by 300ms

  //console.log("filteredOptions: ", filteredOptions);

  // Effect to trigger search on searchTerm change
  useEffect(() => {
    // console.log("Options : ", options , "\nfilteredOptions : " , filteredOptions,"\nsearchTerm: ", searchTerm);
    handleSearch(searchTerm);
  }, [handleSearch , searchTerm]);

  // Close dropdown if clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Add event listener to close dropdown on outside click
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative w-full flex flex-col justify-center">
      <label className="block text-slate-700 !text-[10px]  !sm:text-xs font-semibold tracking-wider font-libre mb-1">
        {label}
      </label>
      <div
        className="w-full border bg-white text-xs md:text-sm rounded px-2 md:px-3 py-2 font-libre font-bold capitalize"
        onClick={() => setOpen(!open)}
      >
        {selected}
      </div>
      {open && (
        <div
          className={`absolute bg-white border rounded w-full z-50 ${down ? "top-[100%] translate-y-1" : "bottom-[100%] -translate-y-1"} shadow-md`}
        >
          { options[0] !== "Select Guests" &&
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 text-sm border-b outline-none"
          />
}
          <ul className="max-h-32 overflow-y-scroll">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((city, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(city)}
                  className="p-1.5 text-sm hover:bg-gray-200 cursor-pointer"
                >
                  {city}
                </li>
              ))
            ) : (
              <li className="p-1.5 text-sm text-gray-500">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
