
import { FaUser, FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';
import { FiMessageSquare } from 'react-icons/fi';
import { BsCheckCircle } from 'react-icons/bs';
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { Link } from "react-router-dom";
//-----------------------------------
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { facilities } from "../data/data.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import Header2 from "../components/Header2"
import GFooter from "../components/GFOOTER"
import { setTripList } from '../redux/state';
import { useSelector, useDispatch } from "react-redux";
import { removeProperty } from "../redux/state";
import { toast } from "react-toastify";


const Details = () => {
  const user = useSelector((state) => state.user);
  const modelType = useSelector((state) => state.modelType);
  const token = useSelector((state) => state.token);
  // const [loading, setLoading] = useState(true);
  const [del, setDel] = useState(false);
  const [Adel, setADel] = useState(false);
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const dispatch = useDispatch();

  const getListingDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/properties/${listingId}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();

      const f = data.data.facilities;
      const facilitiesArray = f.length === 1 ? f[0].split(',').map(item => item.trim()) : f;
      setListing({
        ...data.data,
        facilities: facilitiesArray,
      });
      console.log("(Details.jsx) Listing Data: ", data?.data);
    } catch (err) {
      console.log("Fetch Listing Details Failed", err.message);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    getListingDetails();
    // eslint-disable-next-line
  }, []);

  /* BOOKING CALENDAR */
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    // Update the selected date range when user makes a selection
    setDateRange([ranges.selection]);
  };
  const start = new Date(dateRange[0].startDate);
  const end = new Date(dateRange[0].endDate);
  const dayCount = Math.round(end - start) / (1000 * 60 * 60 * 24); // Calculate the difference in day unit

  //   /* SUBMIT BOOKING */
  //const customerId = useSelector((state) => state?.user?._id)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    try {
      const bookingForm = {
        listingId,
        checkIn: new Date(dateRange[0].startDate).toISOString(), // Convert start date to ISO format
        checkOut: new Date(dateRange[0].endDate).toISOString(),
      }
      ////console.log("booking form", bookingForm);

      if (!user || modelType !== "user") { toast.error("You need to be logged in to book the property."); navigate("/login"); return; }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/users/listings/${listingId}/reservations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(bookingForm)
      })

      if (response.ok) {
        const newTrip = await response.json();
        const updatedTripList = [...(user.tripList), newTrip.data];
        //console.log("new Trip: ", newTrip.data, "updatedTripList: ", updatedTripList);
        dispatch(setTripList(updatedTripList));
        navigate(`/${user?._id}/trips`)
      } else {
        toast.error("This property is already booked for the selected dates. Please try again.")
      }

    } catch (err) {
      //console.log("Booking Failed.", err.message)
      toast.error("Booking Failed. Please try again.")
    }
  }
  //console.log("condition", user?._id === listing?.hostId);

  const checkFutureReservations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings/${listingId}/check-future-reservations`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Use appropriate authentication method
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Future Reservations: ", data);
        if (data.data.length > 0) {
          toast.warning("This property cannot be deleted as it has future reservations.");
          setDel(false);
        } else {
          setDel(true);
        }

        
      } else {
        alert("Failed to check future reservations");
      }
    } catch (err) {
      console.log("Check Future Reservations Failed", err.message);
    }
  };

  const deleteListing = async (e) => {
    e.stopPropagation(); // Prevents the card click event from firing
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings/${listingId}/delete-confirmation`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Use appropriate authentication method
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      dispatch(removeProperty(listingId));
      if (listingId === localStorage.getItem('listId')) {
        localStorage.removeItem('listId');
        localStorage.removeItem('currentStep');
      }
      toast.info("Please confirm the deletion in the email sent to your Email ID.");
      navigate(`/${user?._id}/properties`);
    } else {
      alert("Failed to delete the listing");
    }
  };

  const delAdmin = async (e) => {
    e.stopPropagation(); // Prevents the card click event from firing
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/delete-listing/${listingId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Use appropriate authentication method
          "Content-Type": "application/json",
        },
      }
    );

    if (response?.ok) {
      // dispatch(removeProperty(listingId));
      if (listingId === localStorage?.getItem('listId')) {
        localStorage?.removeItem('listId');
        localStorage?.removeItem('currentStep');
      }
      toast.success("Property Deleted Successfully!");
      navigate(`/admin/dashboard`);
    } else {
      toast.error("Failed to delete the listing");
    }
  };

  return (
    <>
      <Header2 />
      <div className="font-sans mt-10">
        {/* Main Content Section */}
        <main className="relative grid grid-cols-2 gap-4 p-4 h-full ">
          {/* -------IMAGES------- */}
          {
            listing?.listingPhotoPaths?.slice(0, 2).map((img, index) => (
              <div key={index} className="relative">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${img.replace("public", "")}`}
                  alt="Property pictures"
                  className="w-full  h-[250px] sm:h-[320px] object-cover"
                />
                {
                  index === 1 && (
                    <Link to={`/gallery/${listingId}`}>
                      <button className="absolute  bottom-2  sm:bottom-4 right-2 sm:right-4 bg-white text-black px-3 sm:px-4 py-1  sm:py-2 shadow-lg font-semibold text-xs sm:text-medium">
                        View All photos
                      </button>
                    </Link>)
                }

              </div>
            ))
          }

        </main>

        <div className="max-w-7xl mx-auto p-4 bg-gray-50">
          {/* Main Content */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2">
              <div className="w-full flex justify-between items-center">
              <div className="text-4xl font-bold">{listing?.title}</div>
              {/* --------------------------ADMIN DELETE PROPERTY GTM----------------------- */}
              {modelType==="admin" && 
              <div className=" font-semibold text-sm py-2 px-4 rounded bg-red-500/90 hover:bg-red-500 cursor-pointer text-white" onClick={()=> setADel(!Adel)}>Delete this property</div>}
              </div>
              <div className="flex items-center space-x-1 sm:space-x-2 mt-1 text-black text-xs sm:text-sm">
                <span className="flex items-center font-bold "> {listing?.city} , </span>
                <span className="flex items-center font-bold"> {listing?.province} , </span>
                <span className="flex items-center font-bold"> {listing?.country}</span>
              </div>
              <div className="flex items-center space-x-1 sm:space-x-2 mt-4 text-gray-700 text-xs sm:text-sm">
                <span className="flex items-center font-medium "> {listing?.category} </span>
                <span className="flex items-center font-bold "> | </span>
                <span className="flex items-center font-medium"> {listing?.type}  </span>
              </div>
              <div className="grid grid-cols-2 sm:!flex sm:!items-center gap-3 sm:gap-4  mt-1 text-gray-600 text-xs sm:text-sm">
                <span className="flex items-center"><FaUser className="mr-1" /> {listing?.guestCount} guests</span>
                <span className="flex items-center "><FaBed className="mr-1" /> {listing?.bedroomCount} bedroom(s), {listing?.bedCount} bed(s)</span>
                <span className="flex items-center"><FaBath className="mr-1" /> {listing?.bathroomCount} bathroom(s)</span>
                <span className="flex items-center"><FaRulerCombined className="mr-1" /> 'Area' sq/m</span>
              </div>
              <p className="mt-4 text-gray-700 text-justify">
                <b>{listing?.highlightDesc} </b> <br />
                {listing?.description}
              </p>
            </div>

            {/* Availability and Contact Section */}
            {modelType !== 'host' &&
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div>
                  <h2 className="text-xl sm:text-2xl tracking-wide font-semibold mb-2 text-slate-700 ">How long do you want to stay?</h2>
                  <div className="flex items-center space-x-2 mb-4">
                    {/* =============CALENDAR================= */}
                    {modelType !== "host" && (
                      <div className="">

                        <div className="date-range-calendar flex flex-col items-center p-2 ">
                          <DateRange ranges={dateRange} onChange={handleSelect} rangeColors={['#f43f5e', '#f43f5e', '#f43f5e']} className=" " />

                          <div className="w-full flex flex-row justify-between items-center  font-medium text-[10px] sm:text-xs">

                            <p>Start Date: {dateRange[0].startDate.toDateString()}</p>
                            <p>End Date: {dateRange[0].endDate.toDateString()}</p>
                          </div>
                          <div className="flex w-full justify-between items-center py-2 text-md">

                            <h2 className=" text-sm sm:text-md font-semibold">
                              {`${listing?.price} x ${dayCount} Night${dayCount > 1 ? "s" : ""}`}
                            </h2>

                            <span className="font-semibold text-right text-sm sm:text-md ">Total Price = ${listing?.price * dayCount}</span>
                          </div>

                          <button onClick={handleSubmit} className="button w-full rounded-md bg-slate-700 hover:bg-slate-800 text-white flex items-center justify-center text-xl font-semibold tracking-wide py-2 mt-2" type="submit" >
                            Check Availability
                          </button>
                        </div>
                      </div>
                    )}
                    {/* =============CALENDAR================= */}
                  </div>

                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-semibold mb-2">Ask the host anything</h3>
                  <p className="text-gray-600">Do you have a question about this home or the local area?</p>
                  <Link to="#" className="text-slate-800 font-semibold mt-2 inline-block">
                    Message the host <FiMessageSquare className="inline ml-1" />
                  </Link>
                </div>
              </div>}
          </div>

          {/* Facilities Section */}
          <div className="booking  w-full flex flex-col lg:flex-row justify-between gap-4 my-4">
            <div>
              <h2 className="sm:text-2xl tracking-wide text-black text-2xl font-bold my-4">What this place offers?</h2>
              <div className="facilities grid grid-cols-2 gap-x-4 md:gap-x-10  ">
                {listing?.facilities?.map((item, index) => (
                  <div className="facility flex items-center p-1 gap-1 sm:gap-2" key={index}>
                    <div className="facility_icon text-lg md:text-xl font-bold m-0.5  sm:m-2 text-gray-900 ">
                      {
                        facilities.find((facility) => facility.name === item)
                          ?.icon
                      }
                    </div>
                    <p className="text-xs sm:text-sm font-medium tracking-wide text-gray-700">{item}</p>
                  </div>
                ))}
              </div>

            </div>

          </div>
          {/* ======================PROMISE SECTION========================= */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">The Promise We Keep</h2>
            <ul className="space-y-2 text-gray-700">
              <li className="flex items-start"><BsCheckCircle className="h-6 w-6 text-yellow-500 mr-2" />No time for average – We rejected many homes , so you don't have to.</li>
              <li className="flex items-start"><BsCheckCircle className="h-6 w-6 text-yellow-500 mr-2" />Hosts we know – For high-quality hosting.</li>
              <li className="flex items-start"><BsCheckCircle className="h-6 w-6 text-yellow-500 mr-2" /> Exceptional service before, during and after your stay.</li>
            </ul>
            <Link to="/FAQ" className="text-slate-800 font-semibold mt-4 inline-block">
              Learn about our Promise
            </Link>
          </div>

          {/*===================== DELETE LISTING BUTTON ======================== */}
          {(modelType === "host" && listing?.hostId === user?._id) && (
            <>
              <div className="w-full flex justify-end pr-8 gap-4 ">

                {!del && <button
                  onClick={() => navigate(`/edit-listing/${listingId}`, { state: { listingData: listing } })}
                  className=" text-blue-400 !border !border-blue-400 !bg-transparent hover:bg-blue-500 font-semibold flex items-center justify-start py-1.5 px-3 space-x-2 rounded shadow-md"
                >
                  <span> <FaPen className='text-sm' />  </span>
                  <span>Update  </span>
                </button>
                }
                {del ? <>
                    <div className='flex items-center justify-end gap-2'>
                      <button
                        onClick={() => setDel(false)}
                        className=" bg-gray-500 hover:bg-gray-600 text-white font-semibold py-1.5 px-3 rounded"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={deleteListing}
                        className=" bg-red-500 hover:bg-red-600 text-white font-semibold py-1.5 px-3 rounded"
                      >
                        Confirm Delete
                      </button>
                    </div>
                </>
                  :
                  <button
                    onClick={() => { checkFutureReservations()}}
                    className=" text-red-400 !border !border-red-400 !bg-transparent hover:bg-red-500  font-semibold flex items-center justify-start py-1.5 px-3 space-x-2 rounded shadow-md"
                  >
                    <span> <MdDelete />  </span>
                    <span>Delete  </span>
                  </button>
                }
              </div>
            </>
          )}

          {/*===================== ---ADMIN--- DELETE LISTING BUTTON GTM ======================== */}
          {
            Adel && (
              <div className="fixed top-0 left-0  bg-black/50 w-full h-screen flex items-center justify-center ">
                <div className='flex flex-col items-center justify-start  w-72 px-8 py-6 bg-white rounded-md'>
                  <span><IoWarning className='text-[100px] text-red-500 p-0'/></span>
                  <h2 className="text-xl text-center font-semibold text-slate-800 !my-1 pb-3">Are you sure to delete this property?</h2>
                  <div className="text-xs text-justify text-slate-500 font-semibold pb-4">Proceeding would delete the selected property permanently from the records.</div>
                  <div className="w-full flex justify-between gap-1 items-center ">
                  <div
                    onClick={() => setADel(false)}
                     className="w-full text-center cursor-pointer text-sm bg-gray-500 hover:bg-gray-600 text-white font-semibold py-1.5 px-3 rounded"
                  >
                    Cancel
                  </div>
                  <div
                    onClick={(e)=>{delAdmin(e); setADel(false)}}
                    className="w-full text-center cursor-pointer text-sm bg-red-500 hover:bg-red-600 text-white font-semibold py-1.5 px-3 rounded"
                  >
                    Confirm 
                  </div>
                </div>
                </div>
              </div>
            )
          }

        </div>

      </div>

      <GFooter />
    </>
  );
};

export default Details;
