import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Card from "./Card";
import "./Card.css";

const Cards = () => {
  const token = useSelector(state => state.token)
  const [totalRevenue , SetTotalRevenue] = useState(0)
 
  
  const getPayments = useCallback(async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const data = await res.json()

        if (res.ok) {
          const totalAmt = data?.data?.reduce((acc, payment) => {
            const amount = parseFloat(payment?.amount);
            return acc + (isNaN(amount) ? 0 : amount);
          }, 0);
          
          console.log("Total Sum:", totalAmt);
          SetTotalRevenue(totalAmt)
          
        }

    } catch (err) {
        console.log(err)
    }
}, [token])

useEffect(() => {
    getPayments()
}, [getPayments])


  return (
    <section className="content-area-cards">
      <Card
        colors={["#e4e8ef", "#475be8"]}
        percentFillValue={80}
        cardInfo={{
          title: "Todays Sales",
          value: "$20.4K",
          text: "We have sold 123 items.",
        }}
      />
      <Card
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={50}
        cardInfo={{
          title: "Total Revenue",
          value: `$ ${totalRevenue}`,
          text: "All payments made so far.",
        }}
        link = "/admin/all-payments"
      />
      <Card
        colors={["#e4e8ef", "#f29a2e"]}
        percentFillValue={40}
        cardInfo={{
          title: "In Escrow",
          value: "$18.2K",
          text: "Available to payout",
        }}
      />
    </section>
  );
};

export default Cards;