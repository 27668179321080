import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLogin } from '../redux/state';

const DeleteListing = () => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const modelType = useSelector((state) => state.modelType);
  const { verificationToken } = useParams(); // Extract token from the URL
  const [message, setMessage] = useState('');
  const [isDeleting, setIsDeleting] = useState(true); // Initially set to true for loading state
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to delete the listing using the token
  const deleteListing = useCallback( async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings/${verificationToken}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Pass the token in the request header if needed
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log('Deleted:', data);
        dispatch(
          setLogin({
            user: {...user, ...data.data},
            token: token,
            modelType: modelType,
          })
        );
        setMessage('Listing has been successfully deleted.');
        // Optionally, redirect the user after a delay
        setTimeout(() => {
          navigate('/all-listings'); // Redirect to the listings page or any other page
        }, 2000);
      } else {
        const errorData = await response.json();
        setMessage(`Failed to delete listing: ${errorData.message}`);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    } finally {
      setIsDeleting(false); // Stop the loading state
    }
  }, [dispatch, modelType, navigate, token, user, verificationToken])

  // useEffect to trigger the deletion on page load
  useEffect(() => {
    if (token) {
      deleteListing(); // Call the delete function on page load
    }
  }, [token, deleteListing]);

  return (
    <div className="flex flex-col items-center mt-10">
      <h1 className="text-2xl font-semibold mb-4">
        {isDeleting ? 'Deleting the Listing...' : 'Delete Listing'}
      </h1>

      {message && (
        <div
          className={`p-4 mb-4 ${
            message.includes('successfully') ? 'bg-green-200' : 'bg-red-200'
          } text-center`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default DeleteListing;
