import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWishList, setPropertyList, setLogin, setSubscriptions } from '../redux/state';
import Header2 from '../components/Header2';
import GFooter from '../components/GFOOTER';
import { toast } from 'react-toastify';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = useLocation().pathname;

  const modelType = currentPath === '/host_login' ? 'host' :
    currentPath === '/login' ? 'user' :
      (currentPath === '/admin' || currentPath==='/admin/' || currentPath === '/admin/login') ? 'admin' :
        null;
  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${modelType}${modelType === "admin" ? "" : "s"}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const loggedIn = await response.json();
      console.log('Logged in:', loggedIn);

      if (loggedIn) {
        dispatch(
          setLogin({
            user: loggedIn.data[modelType === 'admin' ? 'role' : (modelType === 'host' ? 'hostData' : 'user')],
            token: loggedIn.data.accessToken,
            modelType: modelType,
          })
        );

        if (modelType === 'user') {
          const wishlistResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${modelType}s/wishlists`, {
            headers: {
              Authorization: `Bearer ${loggedIn.data.accessToken}`,
            },
          });
          const wishlistData = await wishlistResponse.json();
          dispatch(setWishList(wishlistData));
        }

        if (modelType === 'host') {
          const propertyListResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${modelType}s/get-listings`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${loggedIn.data.accessToken}`,
              'Content-Type': 'application/json',
            },
          });
          const PLdata = await propertyListResponse.json();
          console.log('PropertyList:', PLdata.data);
          dispatch(setPropertyList(PLdata.data));

          const subscriptionsResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${modelType}s/subscriptions`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${loggedIn.data.accessToken}`,
              'Content-Type': 'application/json',
            },
          });
          const SRData = await subscriptionsResponse.json();
          console.log('Subscriptions data:', SRData.data);
          dispatch(setSubscriptions(SRData.data));
        }

        toast.success(`Successfully logged in! Welcome, 
          ${modelType === 'admin' ? "Admin"
            : loggedIn.data[modelType === 'host' ? 'hostData' : 'user'].firstName}.`);

        modelType === 'admin' ? navigate('/admin/dashboard') :
          modelType === 'host' ? navigate('/create-listing') :
            navigate('/dashboard'); //is modeltype is user
      }
    } catch (err) {
      console.log('Login failed', err.message);
      toast.error(`Login failed. Please try again.`);
    }
  };


  const images = ['/assets/G1.jpg', '/assets/G2.jpg', '/assets/G3.jpg', '/assets/G4.jpg'];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <>
      <Header2 />
      <div className='h-screen w-full bg-slate-200 flex justify-center items-center'>
        <div className=' group flex flex-col sm:flex-row justify-center sm:h-96 items-start shadow-xl rounded-md overflow-hidden'>
          {/* ----------Sideimage-------- */}
          <div className='h-56 sm:h-full w-80 relative overflow-hidden'>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`slide-${index}`}
                className={`object-cover h-full w-full absolute inset-0 transition-opacity duration-[2000ms] ease-in-out ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                  }`}
              />
            ))}

            <div className={`absolute translate-y-full duration-1000 group-hover:translate-y-0 inset-0 bg-black/40 text-white font-bold text-2xl sm:text-3xl p-4 flex ${modelType === "admin" && "justify-center"} items-center text-center`}>
              {currentPath === '/login' ? "Your journey starts here. Log in to explore your next stay." : currentPath === '/host_login' ? "Ready to open your doors? Log in to manage your bookings." :
                "Welcome, Admin."
              }</div>

            <div className='absolute bottom-3 left-3 drop-shadow-2xl '>
              <img src="/logo4.png" alt="" className='h-7 w-7  object-cover' />
            </div>
          </div>
          {/* ----------Form-------- */}
          <div className='flex flex-col items-center h-full w-full sm:w-auto'>
            <form onSubmit={handleSubmit} className='flex flex-col items-center justify-start py-4 sm:py-8 px-8 sm:px-12 gap-4 sm:gap-6 bg-white h-full w-80'>
              <h1 className='text-center text-3xl !font-extrabold mb-1 '>
                {`${modelType?.charAt(0).toUpperCase() + modelType?.slice(1)} Login`}
              </h1>

              <input
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email'
                className='py-2 px-4 w-full text-sm bg-gray-50 rounded-sm focus:border-b focus:outline-none'
                required
              />
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password'
                className='py-2 px-4 w-full text-sm bg-gray-50 rounded-sm focus:border-b focus:outline-none'
                required
              />
              <Link className='w-full -mt-3 sm:-mt-5' to={`/${currentPath === '/host_login' ? 'host-' : ''}get-otp`}>
                <div className='text-slate-500 text-xs text-end tracking-wider w-full font-medium'>Forgot password? </div>
              </Link>

              <button
                type='submit'
                className='w-full text-lg bg-slate-700 hover:bg-slate-800 hover:shadow-md px-6 py-2 mt-2 mb-4 text-white rounded-sm'
              >
                Login
              </button>
              {modelType !== 'admin' &&
                <Link className='w-full text-center tracking-wide text-sm' to={`/${currentPath === '/host_login' ? 'host_' : ''}register`}>
                  Don't have an account? <span className='text-slate-700 font-semibold'>Sign Up</span>
                </Link>
              }
            </form>
          </div>
        </div>
      </div>
      <GFooter />
    </>
  );
};
