import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import GCL1 from '../components/GCL1';
import GCL2 from '../components/GCL2';
import Header from "../components/Header2";
import Footer from "../components/GFOOTER";
import { setLogin } from '../redux/state';
import { toast } from 'react-toastify';

const CreateListing = () => {
    const [step, setStep] = useState(1);
    const [listId, setListId] = useState(null);
    const [formData, setFormData] = useState({
        _id: "",
        category: "",
        type: "",
        streetAddress: "",
        aptSuite: "",
        city: "",
        province: "",
        country: "",
        guestCount: "",
        bedroomCount: "",
        bedCount: "",
        bathroomCount: "",
        facilities: [],
        title: "",
        description: "",
        highlight: "",
        highlightDesc: "",
        price: "",
        listingPhotoPaths: [],
    });


    const user = useSelector((state) => state.user);
    // console.log("(Gcreatelisting) user: ", user);
    const modelType = useSelector((state) => state.modelType);
    const token = useSelector((state) => state.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const { subscriptionId } = location?.state || {};

    const [paid, setPaid] = useState(false);
    const [subscriptionID, setSubscriptionID] = useState('');

    const handleChange = (e) => {
        setSubscriptionID(e.target.value);
    };
    //console.log("typeof", typeof user?.subscriptions)
    //console.log("user: ", user,"\nsubscriptions: ",user?.subscriptions,"\nPropertyList: ", user?.propertyList?.length);
    // console.log("subscriptions: ", user?.subscriptions, "\nPropertyList: ", user?.propertyList?.length);
    useEffect(() => {
        if (user?.subscriptions?.length === user?.propertyList?.length) {
            // console.log("user?.listStep===2 ==> ", user?.listStep===2);
            user?.listStep === 2 ? setPaid(true) : setPaid(false);
        } else if (user?.subscriptions?.length > user?.propertyList?.length) {
            setPaid(true);
        }
        if (!paid) {
            const timeoutId = setTimeout(() => {
                toast.info("You need to buy a plan to create a listing.");
                navigate('/plans');
            }, 500);

            return () => clearTimeout(timeoutId);
        }
    }, [paid, navigate, user?.propertyList?.length, user?.listStep, user?.subscriptions?.length, location.state]);

    useEffect(() => {
        const savedListId = user?.listId;

        if (savedListId) {
            setListId(savedListId);
            //            console.log("(GCREATELISTING) user:", user);
            setStep(2);
        }
    }, [user?.listId]);

    const handleNextStep = async () => {
        try {
            const listId = user?.listId;
            const url = listId
                ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings/${listId}/update-details`
                : `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings`;

            const method = listId ? 'PATCH' : 'POST';
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    category: formData.category,
                    type: formData.type,
                    streetAddress: formData.streetAddress,
                    aptSuite: formData.aptSuite,
                    city: formData.city,
                    province: formData.province,
                    country: formData.country,
                    guestCount: formData.guestCount,
                    bedroomCount: formData.bedroomCount,
                    bedCount: formData.bedCount,
                    bathroomCount: formData.bathroomCount,
                    facilities: formData.facilities,
                    title: formData.title,
                    description: formData.description,
                    highlight: formData.highlight,
                    highlightDesc: formData.highlightDesc,
                    price: formData.price,
                    listingPhotoPaths: formData.listingPhotoPaths,
                    subscriptionId: subscriptionID,
                }),
            });

            // If successful, move to the next step
            if (response.ok) {
                const listing = await response.json();
                // console.log('listing id:', listing.data._id);
                setListId(listing.data._id);

                const updatedHostStep = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/update-step`, {
                    method: "PATCH",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        listStep: 2,
                        listId: listing.data._id,
                    })
                });
                if (updatedHostStep.ok) {

                    const updatedHost = await updatedHostStep.json();
                    // console.log('updatedHost:', updatedHost);

                    dispatch(
                        setLogin({
                            user: { ...user, ...updatedHost.data },
                            token: token,
                            modelType: "host"
                        })
                    );

                    setStep(2);
                } else {
                    console.error("Failed to update host step");
                }
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    console.log("paid: ", paid);

    return (
        <>
            <Header />
            {user && modelType === "host" ?
                <div className='pt-12 bg-gray-100/[0.8]'>
                    <div className='px-4 sm:px-4 md:px-8  lg:px-36  py-8'>
                        <div className="rounded-lg flex flex-col">

                            <div className='font-bold px-1.5 sm:px-2 md:px-2 lg:px-4 py-1 sm:py-1.5 md:py-2 lg:py-2 mb-4 h-10 sm:h-12 md:h-14 lg:h-14 text-lg sm:text-xl md:text-2xl lg:text-3xl w-full text-slate-700 text-center rounded-t-md tracking-wide'>
                                Publish Your Place Here
                            </div>
                            {step === 1 && <>
                                <label htmlFor="subscription-select" className="block mb-2 text-sm text-gray-900 font-bold">Select Subscription Plan <span className="imp">*</span> </label>

                                <select
                                    id="subscription-select"
                                    value={subscriptionID}  // Value controlled by state
                                    onChange={handleChange}  // Update the state when the user selects an option
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none block w-full p-2.5"
                                >
                                    <option value="" disabled>Select the subscription plan under which you wish to create the listing</option>
                                    {user?.subscriptions && user?.subscriptions?.map((sub) => (
                                        <option key={sub._id} value={sub._id}>
                                            {sub.name}
                                        </option>
                                    ))}
                                </select>

                                {/* {subscriptionID && (
                                    <p className="mt-2 w-full text-slate-700 text-end text-xs sm:text-sm font-semibold ">Selected Subscription ID: {subscriptionID}</p>
                                )} */}
                            </>}

                            <div className="relative">
                                {step === 1 && (
                                    <GCL1
                                        formData={formData}
                                        setFormData={setFormData}
                                        handleNextStep={handleNextStep}
                                        subID={subscriptionID}
                                    />
                                )}
                                {step === 2 && (
                                    <GCL2
                                        formData={formData}
                                        setFormData={setFormData}
                                        setStep={setStep}
                                        id={listId}
                                        subID={subscriptionID}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                </div> :
                <div className="h-screen font-semibold bg-gradient-to-r from-slate-300 to-white flex justify-center items-center tracking-wider text-lg">
                    You are not authorized to view this page.
                    <Link to="/host_login" className='text-blue-700 px-1'>Login as Host?</Link>
                </div>
            }
            <Footer />
        </>
    );
};

export default CreateListing;
