import React, { useState } from 'react';
import {  useSelector } from "react-redux";
import { Link, useLocation, useParams } from 'react-router-dom';
import GCL1 from '../components/GCL1';
import GCL2 from '../components/GCL2';
import Header from "../components/Header2";
import Footer from "../components/GFOOTER";
//import { setLogin } from '../redux/state';
//import { toast } from 'react-toastify';

const GEDITLISTING = () => {
    const [step, setStep] = useState(1);
    // const [listId, setListId] = useState(null);
    const [formData, setFormData] = useState({
        _id: "",
        category: "",
        type: "",
        streetAddress: "",
        aptSuite: "",
        city: "",
        province: "",
        country: "",
        guestCount: "",
        bedroomCount: "",
        bedCount: "",
        bathroomCount: "",
        facilities: [],
        title: "",
        description: "",
        highlight: "",
        highlightDesc: "",
        price: "",
        listingPhotoPaths: [],
    });
    const { listingId } = useParams();


    const user = useSelector((state) => state.user);
    const modelType = useSelector((state) => state.modelType);
    const token = useSelector((state) => state.token);
    const [subscriptionID, setSubscriptionID] = useState('');
 
    const location = useLocation();
    const {listingData} = location.state;

   console.log("Listing Data : " , listingData)
  //    const [paid, setPaid] = useState(false);

    const handleNextStep = async () => {
        console.log("(handleNextStep) Formdata → ", formData)

        try {
            const listId = listingId
            setSubscriptionID(listingData?.subscriptionId);
            console.log('subscriptionID:', subscriptionID);
            //console.log('host listId:', listId);
            const url =  `${process.env.REACT_APP_BACKEND_URL}/api/v1/hosts/listings/${listId}/update-details`


            const response = await fetch(url, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    category: formData.category,
                    type: formData.type,
                    streetAddress: formData.streetAddress,
                    aptSuite: formData.aptSuite,
                    city: formData.city,
                    province: formData.province,
                    country: formData.country,
                    guestCount: formData.guestCount,
                    bedroomCount: formData.bedroomCount,
                    bedCount: formData.bedCount,
                    bathroomCount: formData.bathroomCount,
                    facilities: formData.facilities,
                    title: formData.title,
                    description: formData.description,
                    highlight: formData.highlight,
                    highlightDesc: formData.highlightDesc,
                    price: formData.price,
                    listingPhotoPaths: formData.listingPhotoPaths,
                    subscriptionId : subscriptionID,
                }),
            });

            // If successful, move to the next step
            if (response.ok) {
                   console.log("STEP 1 updated successfully");
                   console.log("(response.ok) Formdata → ", formData)
                   setStep(2); 

                } else {
                    console.error("Failed to update host step");
                }
            
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };
    const step1Data = {
        category: listingData?.category,

        type: listingData?.type,

        streetAddress: listingData?.streetAddress,
        aptSuite: listingData?.aptSuite,
        city: listingData?.city,
        province: listingData?.province,
        country: listingData?.country,

        guestCount: listingData?.guestCount,
        bedroomCount: listingData?.bedroomCount,
        bedCount: listingData?.bedCount,
        bathroomCount: listingData?.bathroomCount,
    }
    
    const step2Data = {
        listID : listingData?._id,
        facilities: listingData?.facilities,
        title: listingData?.title,
        description: listingData?.description,
        highlight: listingData?.highlight,
        highlightDesc: listingData?.highlightDesc,
        price: listingData?.price,
        listingPhotoPaths: listingData?.listingPhotoPaths,
    }
    return (
        <>
            <Header />
            {user && modelType === "host" ?
                <div className='pt-12 bg-gray-100/[0.8]'>
                    <div className='px-4 sm:px-4 md:px-8  lg:px-36  py-8'>
                        <div className="rounded-lg flex flex-col">
                            <div className='font-bold px-1.5 sm:px-2 md:px-2 lg:px-4 py-1 sm:py-1.5 md:py-2 lg:py-2 mb-4 h-10 sm:h-12 md:h-14 lg:h-14 text-lg sm:text-xl md:text-2xl lg:text-3xl w-full text-slate-700 text-center rounded-t-md tracking-wide'>
                                Update your listing
                            </div>

                            {/* {step === 1 && <>
                                <label htmlFor="subscription-select" className="block mb-2 text-sm text-gray-900 font-bold">Select Subscription Plan <span className="imp">*</span> </label>

                                <select
                                    id="subscription-select"
                                    value={subscriptionID}  // Value controlled by state
                                    onChange={handleChange}  // Update the state when the user selects an option
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none block w-full p-2.5"
                                >
                                    <option value="" disabled>Select the subscription plan under which you wish to create the listing</option>
                                    {user?.subscriptions && user?.subscriptions?.map((sub) => (
                                        <option key={sub?._id} value={sub?._id}>
                                            {sub?.name} | Plan ID : {sub?.planId} | Price: {sub?.discountedPrice}
                                        </option>
                                    ))}
                                </select>

                                {subscriptionID && (
                                    <p className="mt-2 w-full text-slate-700 text-end text-xs sm:text-sm font-semibold ">Selected Subscription ID: {subscriptionID}</p>
                                )}
                            </>} */}

                            <div className="relative">
                                {step === 1 && (
                                    <GCL1
                                        formData={formData}
                                        setFormData={setFormData}
                                        handleNextStep={handleNextStep}
                                        step1data = {step1Data}
                                        subID={subscriptionID}
                                    />
                                )}
                                {step === 2 && (
                                    <GCL2
                                        formData={formData}
                                        setFormData={setFormData}
                                        setStep={setStep}
                                        id={listingId}
                                        step2data = {step2Data}
                                        subID={subscriptionID}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="h-screen font-semibold bg-gradient-to-r from-slate-300 to-white flex justify-center items-center tracking-wider text-lg">
                    You are not authorized to view this page.
                    <Link to="/host_login" className='text-blue-700 px-1'>Login as Host?</Link>
                </div>
            }
            <Footer />
        </>
    );
};

export default GEDITLISTING;
