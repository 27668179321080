import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header2';
import Footer from '../components/GFOOTER';
import { toast } from 'react-toastify';

const GPAY = () => {
  const [loading, setLoading] = useState(false);
  //Getting selected plan details from /plans GTM
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location?.state?.plan) {
      toast.warn('No plan selected, redirecting to plans.');
      navigate('/plans', { replace: true });
    }
  }, [location, navigate]);

  const { plan } = location?.state || {};

  if (!plan) return null; 

  const amount = plan?.price - (plan?.discount * plan?.price / 100);

  const createOrder = async (event) => {
    event.preventDefault();  // Prevent form from refreshing the page

    // Send the payment amount to the backend
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/create-order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        amount,
        returnUrl: `${process.env.REACT_APP_FRONTEND_URL}/payment/success?planName=${plan?.name}`,
        cancelUrl: `${process.env.REACT_APP_FRONTEND_URL}/payment/fail`
        // returnUrl: `http://localhost:3000/payment/success?planName=${plan?.name}`,
        // cancelUrl: `http://localhost:3000/payment/fail`
      })
    });

    if (response.ok) {
      const {  approvalUrl } = await response.json();
     // console.log('OrderId:', orderId, '\nApproval URL:', approvalUrl);
      // Redirect the user to the approval URL
      window.location = approvalUrl;
    } else {
      console.error('Order creation failed');
    }
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  return (
    <>
      <Header />
      <div className="py-16 min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white p-4 sm:p-6 rounded shadow-md w-full max-w-sm">
          <h2 className="text-2xl font-semibold text-center mb-4">Payment</h2>
          <div className="flex justify-center mb-4">
            <img src="/assets/payment.png" alt="payment" />
          </div>

          <div className="w-full flex justify-between items-center py-3 ">
            <div className='flex flex-col items-start justify-center'>
              <div className="text-center text-sm font-semibold text-slate-700">Plan ID</div>
              <div className="text-center text-xl mb-6 font-extrabold">
                {plan?.id}
              </div>
            </div>
            <div className='flex flex-col items-end justify-center'>
              <div className="text-center text-sm font-semibold text-slate-700">Payable amount</div>
              <div className="text-center text-2xl mb-6 font-extrabold">
                {plan?.price - (plan?.discount * plan?.price / 100)}
                <span className="text-base text-slate-900"> {plan?.currency}</span>
              </div>
            </div>
          </div>
          {/* <div className="text-center text-lg font-semibold mb-2">Payment amount</div>
          <div className="text-center text-xl mb-6">${amount}</div> */}

          <form onSubmit={createOrder}>
            <div className='flex flex-col gap-1.5 items-between justify-between mb-4'>
              <div className="flex items-center justify-start font-bold w-full border-b text-lg "> Plan Details</div>
              <div className="flex justify-between items-center px-2 " >
                <div className="text-sm font-semibold text-slate-700"> Name</div>
                <div className="text-sm font-extrabold  text-slate-700">{plan?.name}</div>
              </div>
              <div className="flex justify-between items-center px-2" >
                <div className="text-sm font-semibold text-slate-700">Price</div>
                <div className="text-sm font-extrabold text-slate-700">{plan?.price} {plan?.currency}</div>
              </div>
              <div className="flex justify-between items-center px-2" >
                <div className="text-sm font-semibold text-slate-700">Validity</div>
                <div className="text-sm font-extrabold text-slate-700">{plan?.period}</div>
              </div>
              <div className="flex justify-between items-center px-2" >
                <div className="text-sm font-semibold text-slate-700">Discount</div>
                <div className="text-sm font-extrabold text-slate-700">{plan?.discount}</div>
              </div>
              <div className="flex justify-between items-center  border-t px-1.5" >
                <div className="text-base font-extrabold">Total Amount</div>
                <div className="text-base font-extrabold">{plan?.price - (plan?.discount * plan?.price / 100)} {plan?.currency}</div>
              </div>
            </div>

            <div className="pt-4 flex w-full justify-between items-center gap-2">
              <button
                onClick={() => { toast.info("Payment Cancelled!"); navigate('/plans') }}
                className="w-full py-2 !bg-transparent !bg-none !border !border-slate-800  text-slate-800 font-semibold rounded shadow-md focus:outline-none "
              >
                Cancel
              </button>

              <button
                type="submit"
                className="relative w-full py-2 bg-slate-800 border border-slate-800 hover:bg-slate-900 text-white font-semibold rounded shadow-md focus:outline-none overflow-hidden "
                onClick={() => setLoading(true)}
              >
                Proceed to Pay
                {loading &&
                  <div className={`absolute w-full inset-0 top-0 right-0  bg-white/50  flex items-center justify-center `}>
                    <div className="w-5 h-5 border-4 border-white border-r-slate-800 rounded-full animate-spin"></div>
                  </div>
                }

              </button>
            </div>
          </form>

        </div>
      </div>
      <Footer />
    </>
  )
};
export default GPAY;

