import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const LeadCard = ({ lead }) => {
  const route = useLocation().pathname
  // console.log("(LeadCard.jsx) LEAD → ", lead)
  return (
    <>
      <Link to={`/CRM/lead-details/${lead._id}`}>
        <div className="grid grid-cols-4 p-3 gap-2 w-full border shadow bg-white/70 hover:bg-white rounded">
          <div className="flex gap-2">
            {route !== "/CRM/leads" && <div className="w-[85px]  flex justify-between font-medium">
              <span className="">Name</span>
              <span className="">:</span>
            </div>
            }
            <div className="tracking-wider w-full text-center">
              {lead.firstName + " " + lead.lastName}
            </div>
          </div>
          <div className="flex gap-2">
            {route !== "/CRM/leads" &&
              <div className="w-[85px]  flex justify-between font-medium">
                <span className="">Contact</span>
                <span className="">:</span>
              </div>
            }
            <div className="tracking-wider w-full text-center">
              {lead.phoneNumber}
            </div>
          </div>
          <div className="flex gap-2">
            {route !== "/CRM/leads" &&
              <div className="w-[85px]  flex justify-between font-medium">
                <span className="">Email</span>
                <span className="">:</span>
              </div>}

            <div className="tracking-wider w-full text-center">
              {lead.email}
            </div>
          </div>
          <div className="flex gap-2">
            {route !== "/CRM/leads" &&
              <div className="w-[85px]  flex justify-between font-medium">
                <span className="">Lead Status</span>
                <span className="">:</span>
              </div>
            }
            <div className="tracking-wider w-full text-center">
              {lead.hostStatus === "Ok to send offer" ? "Offer Sent" : lead.hostStatus}
            </div>

          </div>
        </div>

      </Link>
    </>
  )
}

export default LeadCard