import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Component to handle map centering
const MapUpdater = ({ location, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([location.lat, location.lng], zoom);
  }, [location, zoom, map]);

  return null;
};

const MapComponent = ({search}) => {
  const [location, setLocation] = useState({ lat: 40.7128, lng: -74.0060 }); // Default to NYC
  const [input, setInput] = useState('');
  const [marker, setMarker] = useState(null);
  const [zoom, setZoom] = useState(13); // Default zoom level

  const handleSearch = useCallback(() => {
    
    console.log('Searching for:', input);

    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${input}`)
      .then((response) => {
        console.log('Response:', response);
        return response.json();
      })
      .then((data) => {
        console.log('Data:', data);
        if (data.length > 0) {
          const { lat, lon, display_name } = data[0];
          setLocation({ lat: parseFloat(lat), lng: parseFloat(lon) });
          setMarker({ lat: parseFloat(lat), lng: parseFloat(lon) });

          // Set zoom level based on the type of search
          if (display_name.includes(',')) {
            // If the name has commas, it's likely a city
            setZoom(13);
          } else {
            // Otherwise, assume it's a country or larger area
            setZoom(5);
          }
        } else {
          console.log('Searching...');
        }
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
      });
    }, [input]);

  useEffect(() => {
    if(search){
      setInput(search);
      handleSearch();
    }
  } , [input,search, handleSearch]);

  return (
    <div className='flex flex-col gap-2 my-2'>
      <div className="w-full flex justify-end items-center gap-2">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter a location"
        className='w-1/2 p-2 text-sm border rounded outline-none'
      />
      <button onClick={handleSearch} className='!bg-slate-700 !p-2 !px-4 text-sm'>Search</button>
      </div>

      <MapContainer
        center={[location.lat, location.lng]}
        zoom={zoom}
        style={{ height: '400px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapUpdater location={location} zoom={zoom} />
        {marker && (
          <Marker position={[marker.lat, marker.lng]}>
            <Popup>{input}</Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default MapComponent;
